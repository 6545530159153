import { Injectable } from '@angular/core';
import { TranslateService } from 'app/services/translate/translate.service';
import { cloneDeep } from 'lodash';
import { AccessibilityGuidelineInfoService } from './accessibility-guideline-info.service';

type NameValueType = { name: string, value: string };
type NameValueAsNumberType = { name: string, value: number };
type NameValueGroupType = { name: string, value: string, group: string };

@Injectable({
    providedIn: 'root',
})
export class AccessibilityChecksHelperService {

    tags: NameValueGroupType[] = [];
    responsibilities: NameValueType[] = [];
    levels: NameValueAsNumberType[] = [];
    impactLevels: NameValueType[] = [];
    guidelines: NameValueType[] = [];
    successCriteria: NameValueType[] = [];
    htmlElements: NameValueType[] = [];

    constructor (
        private translateService: TranslateService,
        private accessibilityGuidelineInfoService: AccessibilityGuidelineInfoService,
    ) {

        this.setupTags();
        this.setupResponsibilities();
        this.setupLevels();
        this.setupImpactLevels();
        this.setupGuidelines();
        this.setupSuccessCriteria();
        this.setupHTMLElements();
    }

    public getLevels (): NameValueAsNumberType[] {
        return cloneDeep(this.levels);
    }

    public getTags (): NameValueGroupType[] {
        return cloneDeep(this.tags);
    }

    public getResponsibilities (): NameValueType[] {
        return cloneDeep(this.responsibilities);
    }

    public getImpactLevels (): NameValueType[] {
        return cloneDeep(this.impactLevels);
    }

    public getGuidelines (): NameValueType[] {
        return cloneDeep(this.guidelines);
    }

    public getSuccessCriteria (): NameValueType[] {
        return cloneDeep(this.successCriteria);
    }

    public getHTMLElements (): NameValueType[] {
        return cloneDeep(this.htmlElements);
    }

    private setupTags (): void {
        this.tags = [
            {
                name: this.translateService.getString('Screen Reader'),
                value: 'screen_reader',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Keyboard'),
                value: 'keyboard',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Visually impaired'),
                value: 'visually_impaired',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Blind'),
                value: 'blind',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Color blind'),
                value: 'color_blind',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Physically impaired'),
                value: 'physically_impaired',
                group: this.translateService.getString('Optimize for'),
            },
            {
                name: this.translateService.getString('Official'),
                value: 'official',
                group: this.translateService.getString('Standard'),
            },
            {
                name: this.translateService.getString('Recommended'),
                value: 'recommended',
                group: this.translateService.getString('Standard'),
            },
        ];
    }

    private setupResponsibilities (): void {
        this.responsibilities = [
            {
                name: this.translateService.getString('Visual Design'),
                value: 'Visual Design',
            },
            {
                name: this.translateService.getString('UX Design'),
                value: 'UX Design',
            },
            {
                name: this.translateService.getString('Content Authoring'),
                value: 'Content Authoring',
            },
            {
                name: this.translateService.getString('Front-end Development'),
                value: 'Front-end Development',
            },
        ];
    }

    private setupLevels (): void {
        this.levels = [];
        for (let i = 1; i <= 10; i++) {
            this.levels.push({
                name: `${this.translateService.getString('Level')} ${i}`,
                value: i,
            });
        }
    }

    private setupImpactLevels (): void {
        this.impactLevels = [
            {
                name: this.translateService.getString('Minor'),
                value: 'minor',
            },
            {
                name: this.translateService.getString('Moderate'),
                value: 'moderate',
            },
            {
                name: this.translateService.getString('Serious'),
                value: 'serious',
            },
            {
                name: this.translateService.getString('Critical'),
                value: 'critical',
            },
        ];
    }

    private setupGuidelines (): void {
        this.guidelines = this.accessibilityGuidelineInfoService.guidelines.filter(guideline => {
            return guideline.type === 'wcag' && !guideline.isMobile;
        });
    }

    private setupSuccessCriteria (): void {
        this.successCriteria = [
            {
                name: this.translateService.getString('1.2.1 Audio-only and Video-only (Prerecorded) (Level A)'),
                value: '1.2.1',
            },
            {
                name: this.translateService.getString('1.2.2 Captions (Prerecorded) (Level A)'),
                value: '1.2.2',
            },
            {
                name: this.translateService.getString('1.2.3 Audio Description or Media Alternative (Prerecorded) (Level A)'),
                value: '1.2.3',
            },
            {
                name: this.translateService.getString('1.2.4 Captions (Live) (Level AA)'),
                value: '1.2.4',
            },
            {
                name: this.translateService.getString('1.2.5 Audio Description (Prerecorded) (Level AA)'),
                value: '1.2.5',
            },
            {
                name: this.translateService.getString('1.2.6 Sign Language (Prerecorded) (Level AAA)'),
                value: '1.2.6',
            },
            {
                name: this.translateService.getString('1.2.7 Extended Audio Description (Prerecorded) (Level AAA)'),
                value: '1.2.7',
            },
            {
                name: this.translateService.getString('1.2.8 Media Alternative (Prerecorded) (Level AAA)'),
                value: '1.2.8',
            },
            {
                name: this.translateService.getString('1.2.9 Audio-only (Live) (Prerecorded) (Level AAA)'),
                value: '1.2.9',
            },
            {
                name: this.translateService.getString('1.3.1 Info and Relationships (Level A)'),
                value: '1.3.1',
            },
            {
                name: this.translateService.getString('1.3.2 Meaningful Sequence (Level A)'),
                value: '1.3.2',
            },
            {
                name: this.translateService.getString('1.3.3 Sensory Characteristics (Level A)'),
                value: '1.3.3',
            },
            {
                name: this.translateService.getString('1.3.4 Orientation (Level AA)'),
                value: '1.3.4',
            },
            {
                name: this.translateService.getString('1.3.5 Identify Input Purpose (Level AA)'),
                value: '1.3.5',
            },
            {
                name: this.translateService.getString('1.3.6 Identify Purpose (Level AAA)'),
                value: '1.3.6',
            },
            {
                name: this.translateService.getString('1.4.1 Use of Color (Level A)'),
                value: '1.4.1',
            },
            {
                name: this.translateService.getString('1.4.2 Audio Control (Level A)'),
                value: '1.4.2',
            },
            {
                name: this.translateService.getString('1.4.3 Contrast (Minimum) (Level AA)'),
                value: '1.4.3',
            },
            {
                name: this.translateService.getString('1.4.4 Resize text (Level AA)'),
                value: '1.4.4',
            },
            {
                name: this.translateService.getString('1.4.5 Images of Text (Level AA)'),
                value: '1.4.5',
            },
            {
                name: this.translateService.getString('1.4.6 Contrast (Enhanced) (Level AAA)'),
                value: '1.4.6',
            },
            {
                name: this.translateService.getString('1.4.7 Low or No Background Audio (Level AAA)'),
                value: '1.4.7',
            },
            {
                name: this.translateService.getString('1.4.8 Visual Presentation (Level AAA)'),
                value: '1.4.8',
            },
            {
                name: this.translateService.getString('1.4.9 Images of Text (No Exception) (Level AAA)'),
                value: '1.4.9',
            },
            {
                name: this.translateService.getString('1.4.10 Reflow (Level AA)'),
                value: '1.4.10',
            },
            {
                name: this.translateService.getString('1.4.11 Non-text Contrast (Level AA)'),
                value: '1.4.11',
            },
            {
                name: this.translateService.getString('1.4.12 Text Spacing (Level AA)'),
                value: '1.4.12',
            },
            {
                name: this.translateService.getString('1.4.13 Content on Hover or Focus (Level AA)'),
                value: '1.4.13',
            },
            {
                name: this.translateService.getString('2.1.1 Keyboard (Level A)'),
                value: '2.1.1',
            },
            {
                name: this.translateService.getString('2.1.2 No Keyboard Trap (Level A)'),
                value: '2.1.2',
            },
            {
                name: this.translateService.getString('2.1.3 Keyboard (No Exception) (Level A)'),
                value: '2.1.3',
            },
            {
                name: this.translateService.getString('2.1.4 Character Key Shortcuts (Level A)'),
                value: '2.1.4',
            },
            {
                name: this.translateService.getString('2.2.1 Timing Adjustable (Level A)'),
                value: '2.2.1',
            },
            {
                name: this.translateService.getString('2.2.2 Pause, Stop, Hide (Level A)'),
                value: '2.2.2',
            },
            {
                name: this.translateService.getString('2.2.3 No Timing (Level AAA)'),
                value: '2.2.3',
            },
            {
                name: this.translateService.getString('2.2.4 Interruptions (Level AAA)'),
                value: '2.2.4',
            },
            {
                name: this.translateService.getString('2.2.5 Re-authenticatingd (Level AAA)'),
                value: '2.2.5',
            },
            {
                name: this.translateService.getString('2.2.6 Timeouts (Level AAA)'),
                value: '2.2.6',
            },
            {
                name: this.translateService.getString('2.3.1 Three Flashes or Below Threshold (Level A)'),
                value: '2.3.1',
            },
            {
                name: this.translateService.getString('2.3.2 Three Flashes (Level A)'),
                value: '2.3.2',
            },
            {
                name: this.translateService.getString('2.3.3 Animation from Interactions (Level AAA)'),
                value: '2.3.3',
            },
            {
                name: this.translateService.getString('2.4.1 Bypass Blocks (Level A)'),
                value: '2.4.1',
            },
            {
                name: this.translateService.getString('2.4.2 Page Titled (Level A)'),
                value: '2.4.2',
            },
            {
                name: this.translateService.getString('2.4.3 Focus Order (Level A)'),
                value: '2.4.3',
            },
            {
                name: this.translateService.getString('2.4.4 Link Purpose (In Context) (Level A)'),
                value: '2.4.4',
            },
            {
                name: this.translateService.getString('2.4.5 Multiple Ways (Level AA)'),
                value: '2.4.5',
            },
            {
                name: this.translateService.getString('2.4.6 Headings and Labels (Level AA)'),
                value: '2.4.6',
            },
            {
                name: this.translateService.getString('2.4.7 Focus Visible (Level AA)'),
                value: '2.4.7',
            },
            {
                name: this.translateService.getString('2.4.8 Location (Level AAA)'),
                value: '2.4.8',
            },
            {
                name: this.translateService.getString('2.4.9 Link Purpose (Link Only) (Level AAA)'),
                value: '2.4.9',
            },
            {
                name: this.translateService.getString('2.4.10 Section Headings (Level AAA)'),
                value: '2.4.10',
            },
            {
                name: this.translateService.getString('2.5.1 Pointer Gestures (Level A)'),
                value: '2.5.1',
            },
            {
                name: this.translateService.getString('2.5.2 Pointer Cancellation (Level A)'),
                value: '2.5.2',
            },
            {
                name: this.translateService.getString('2.5.3 Label in Name (Level A)'),
                value: '2.5.3',
            },
            {
                name: this.translateService.getString('2.5.4 Motion Actuation (Level A)'),
                value: '2.5.4',
            },
            {
                name: this.translateService.getString('Criterion 2.5.5 Target Size (Level AAA)'),
                value: '2.5.5',
            },
            {
                name: this.translateService.getString('2.5.6 Concurrent Input Mechanisms (Level AAA)'),
                value: '2.5.6',
            },
            {
                name: this.translateService.getString('3.1.1 Language of Page (Level A)'),
                value: '3.1.1',
            },
            {
                name: this.translateService.getString('3.1.2 Language of Parts (Level AA)'),
                value: '3.1.2',
            },
            {
                name: this.translateService.getString('3.1.3 Unusual Words (Level AAA)'),
                value: '3.1.3',
            },
            {
                name: this.translateService.getString('3.1.4 Abbreviations (Level AAA)'),
                value: '3.1.4',
            },
            {
                name: this.translateService.getString('3.1.5 Reading Level (Level AAA)'),
                value: '3.1.5',
            },
            {
                name: this.translateService.getString('3.1.6 Pronunciation (Level AAA)'),
                value: '3.1.6',
            },
            {
                name: this.translateService.getString('3.2.1 On Focus (Level A)'),
                value: '3.2.1',
            },
            {
                name: this.translateService.getString('3.2.2 On Input (Level A)'),
                value: '3.2.2',
            },
            {
                name: this.translateService.getString('3.2.3 Consistent Navigation (Level AA)'),
                value: '3.2.3',
            },
            {
                name: this.translateService.getString('3.2.4 Consistent Identification (Level AA)'),
                value: '3.2.4',
            },
            {
                name: this.translateService.getString('3.2.5 Change on Request (Level AAA)'),
                value: '3.2.5',
            },
            {
                name: this.translateService.getString('3.3.1 Error Identification (Level A)'),
                value: '3.3.1',
            },
            {
                name: this.translateService.getString('3.3.2 Labels or Instructions (Level A)'),
                value: '3.3.2',
            },
            {
                name: this.translateService.getString('3.3.3 Error Suggestion (Level AA)'),
                value: '3.3.3',
            },
            {
                name: this.translateService.getString('3.3.4 Error Prevention (Legal, Financial, Data) (Level AA)'),
                value: '3.3.4',
            },
            {
                name: this.translateService.getString('3.3.5 Help (Level AAA)'),
                value: '3.3.5',
            },
            {
                name: this.translateService.getString('3.3.6 Error Prevention (All) (Level AAA)'),
                value: '3.3.6',
            },
            {
                name: this.translateService.getString('4.1.1 Parsing (Level A)'),
                value: '4.1.1',
            },
            {
                name: this.translateService.getString('4.1.2 Name, Role, Value (Level A)'),
                value: '4.1.2',
            },
            {
                name: this.translateService.getString('4.1.3 Status Messages (Level AA)'),
                value: '4.1.3',
            },
        ];
    }

    private setupHTMLElements (): void {
        this.htmlElements = [
            {
                name: this.translateService.getString('Anchor Element'),
                value: 'a',
            },
            {
                name: this.translateService.getString('Abbreviation Element'),
                value: 'abbr',
            },
            {
                name: this.translateService.getString('Address Element'),
                value: 'address',
            },
            {
                name: this.translateService.getString('Area Element'),
                value: 'area',
            },
            {
                name: this.translateService.getString('Article Element'),
                value: 'article',
            },
            {
                name: this.translateService.getString('Aside Element'),
                value: 'aside',
            },
            {
                name: this.translateService.getString('Audio Element'),
                value: 'audio',
            },
            {
                name: this.translateService.getString('Bold Element'),
                value: 'b',
            },
            {
                name: this.translateService.getString('Base Element'),
                value: 'base',
            },
            {
                name: this.translateService.getString('Bi-Directional Isolation Element'),
                value: 'bdi',
            },
            {
                name: this.translateService.getString('Bi-Directional Override Element'),
                value: 'bdo',
            },
            {
                name: this.translateService.getString('Blockquote Element'),
                value: 'blockquote',
            },
            {
                name: this.translateService.getString('Body Element'),
                value: 'body',
            },
            {
                name: this.translateService.getString('line Break Element'),
                value: 'br',
            },
            {
                name: this.translateService.getString('Button Element'),
                value: 'button',
            },
            {
                name: this.translateService.getString('Canvas Element'),
                value: 'canvas',
            },
            {
                name: this.translateService.getString('Caption Element'),
                value: 'caption',
            },
            {
                name: this.translateService.getString('Citation Element'),
                value: 'cite',
            },
            {
                name: this.translateService.getString('Code Element'),
                value: 'code',
            },
            {
                name: this.translateService.getString('Column Element'),
                value: 'col',
            },
            {
                name: this.translateService.getString('Column Group Element'),
                value: 'colgroup',
            },
            {
                name: this.translateService.getString('Data Element'),
                value: 'data',
            },
            {
                name: this.translateService.getString('Datalist Element'),
                value: 'datalist',
            },
            {
                name: this.translateService.getString('DD Element'),
                value: 'dd',
            },
            {
                name: this.translateService.getString('Deleted Text Element'),
                value: 'del',
            },
            {
                name: this.translateService.getString('Details Element'),
                value: 'details',
            },
            {
                name: this.translateService.getString('Definition Element'),
                value: 'dfn',
            },
            {
                name: this.translateService.getString('Dialog Element'),
                value: 'dialog',
            },
            {
                name: this.translateService.getString('Content Division Element'),
                value: 'div',
            },
            {
                name: this.translateService.getString('Content Division Element'),
                value: 'div',
            },
            {
                name: this.translateService.getString('Description List Element'),
                value: 'dl',
            },
            {
                name: this.translateService.getString('Description Term Element'),
                value: 'dt',
            },
            {
                name: this.translateService.getString('Emphasized Text Element'),
                value: 'em',
            },
            {
                name: this.translateService.getString('Embed Element'),
                value: 'embed',
            },
            {
                name: this.translateService.getString('Fieldset Element'),
                value: 'fieldset',
            },
            {
                name: this.translateService.getString('Figure Caption Element'),
                value: 'figcaption',
            },
            {
                name: this.translateService.getString('Figure Element'),
                value: 'figure',
            },
            {
                name: this.translateService.getString('Footer Element'),
                value: 'footer',
            },
            {
                name: this.translateService.getString('Form Element'),
                value: 'form',
            },
            {
                name: this.translateService.getString('Heading 1 Element'),
                value: 'h1',
            },
            {
                name: this.translateService.getString('Heading 2 Element'),
                value: 'h2',
            },
            {
                name: this.translateService.getString('Heading 3 Element'),
                value: 'h3',
            },
            {
                name: this.translateService.getString('Heading 4 Element'),
                value: 'h4',
            },
            {
                name: this.translateService.getString('Heading 5 Element'),
                value: 'h5',
            },
            {
                name: this.translateService.getString('Heading 6 Element'),
                value: 'h6',
            },
            {
                name: this.translateService.getString('Head Element'),
                value: 'head',
            },
            {
                name: this.translateService.getString('Header Element'),
                value: 'header',
            },
            {
                name: this.translateService.getString('Multi Level Element'),
                value: 'hgroup',
            },
            {
                name: this.translateService.getString('Horizontal Ruler Element'),
                value: 'hr',
            },
            {
                name: this.translateService.getString('HTML Element'),
                value: 'html',
            },
            {
                name: this.translateService.getString('Italic Element'),
                value: 'i',
            },
            {
                name: this.translateService.getString('Iframe Element'),
                value: 'iframe',
            },
            {
                name: this.translateService.getString('Image Element'),
                value: 'img',
            },
            {
                name: this.translateService.getString('Input Element'),
                value: 'input',
            },
            {
                name: this.translateService.getString('HTML Added Element'),
                value: 'ins',
            },
            {
                name: this.translateService.getString('Keyboard Input Element'),
                value: 'kbd',
            },
            {
                name: this.translateService.getString('Label Element'),
                value: 'label',
            },
            {
                name: this.translateService.getString('Legend Element'),
                value: 'legend',
            },
            {
                name: this.translateService.getString('List Item Element'),
                value: 'li',
            },
            {
                name: this.translateService.getString('Link Element'),
                value: 'link',
            },
            {
                name: this.translateService.getString('Main Element'),
                value: 'main',
            },
            {
                name: this.translateService.getString('Map Element'),
                value: 'map',
            },
            {
                name: this.translateService.getString('Mark Text Element'),
                value: 'mark',
            },
            {
                name: this.translateService.getString('Math Element'),
                value: 'math',
            },
            {
                name: this.translateService.getString('Menu Element'),
                value: 'menu',
            },
            {
                name: this.translateService.getString('Menu Item Element'),
                value: 'menuitem',
            },
            {
                name: this.translateService.getString('Meta Header Element'),
                value: 'meta',
            },
            {
                name: this.translateService.getString('Meter Element'),
                value: 'meter',
            },
            {
                name: this.translateService.getString('Navigation Element'),
                value: 'nav',
            },
            {
                name: this.translateService.getString('No Script Element'),
                value: 'noscript',
            },
            {
                name: this.translateService.getString('Object Element'),
                value: 'object',
            },
            {
                name: this.translateService.getString('Ordered List Element'),
                value: 'ol',
            },
            {
                name: this.translateService.getString('Option Group Element'),
                value: 'optgroup',
            },
            {
                name: this.translateService.getString('Option Element'),
                value: 'option',
            },
            {
                name: this.translateService.getString('Output Element'),
                value: 'output',
            },
            {
                name: this.translateService.getString('Paragraph Element'),
                value: 'p',
            },
            {
                name: this.translateService.getString('Object Parameter Element'),
                value: 'param',
            },
            {
                name: this.translateService.getString('Picture Element'),
                value: 'picture',
            },
            {
                name: this.translateService.getString('Preformatted Text Element'),
                value: 'pre',
            },
            {
                name: this.translateService.getString('Progress Element'),
                value: 'progress',
            },
            {
                name: this.translateService.getString('Inline Quotation Element'),
                value: 'q',
            },
            {
                name: this.translateService.getString('Ruby Base Element'),
                value: 'rb',
            },
            {
                name: this.translateService.getString('Ruby Fallback Parenthesis Element'),
                value: 'rp',
            },
            {
                name: this.translateService.getString('Ruby Text Element'),
                value: 'rt',
            },
            {
                name: this.translateService.getString('Ruby Text Container Element'),
                value: 'rtc',
            },
            {
                name: this.translateService.getString('Ruby Text Element'),
                value: 'ruby',
            },
            {
                name: this.translateService.getString('S Element'),
                value: 's',
            },
            {
                name: this.translateService.getString('Sample Element'),
                value: 'samp',
            },
            {
                name: this.translateService.getString('Script Element'),
                value: 'script',
            },
            {
                name: this.translateService.getString('Section Element'),
                value: 'section',
            },
            {
                name: this.translateService.getString('Select Element'),
                value: 'select',
            },
            {
                name: this.translateService.getString('Slot Element'),
                value: 'slot',
            },
            {
                name: this.translateService.getString('Small Element'),
                value: 'small',
            },
            {
                name: this.translateService.getString('Source Element'),
                value: 'source',
            },
            {
                name: this.translateService.getString('Span Element'),
                value: 'span',
            },
            {
                name: this.translateService.getString('Strong Element'),
                value: 'strong',
            },
            {
                name: this.translateService.getString('Style Element'),
                value: 'style',
            },
            {
                name: this.translateService.getString('Subscript Element'),
                value: 'sub',
            },
            {
                name: this.translateService.getString('Disclosure Summary Element'),
                value: 'summary',
            },
            {
                name: this.translateService.getString('Superscript Element'),
                value: 'sup',
            },
            {
                name: this.translateService.getString('SVG Element'),
                value: 'svg',
            },
            {
                name: this.translateService.getString('Table Element'),
                value: 'table',
            },
            {
                name: this.translateService.getString('Table Body Element'),
                value: 'tbody',
            },
            {
                name: this.translateService.getString('Table Cell Element'),
                value: 'td',
            },
            {
                name: this.translateService.getString('Template Element'),
                value: 'template',
            },
            {
                name: this.translateService.getString('Textarea Element'),
                value: 'textarea',
            },
            {
                name: this.translateService.getString('Table Foot Element'),
                value: 'tfoot',
            },
            {
                name: this.translateService.getString('Table Header Element'),
                value: 'th',
            },
            {
                name: this.translateService.getString('Table Head Element'),
                value: 'thead',
            },
            {
                name: this.translateService.getString('Time Element'),
                value: 'time',
            },
            {
                name: this.translateService.getString('Title Element'),
                value: 'title',
            },
            {
                name: this.translateService.getString('Table Row Element'),
                value: 'tr',
            },
            {
                name: this.translateService.getString('Embed Track Element'),
                value: 'track',
            },
            {
                name: this.translateService.getString('Underline Element'),
                value: 'u',
            },
            {
                name: this.translateService.getString('Unordered List Element'),
                value: 'ul',
            },
            {
                name: this.translateService.getString('Variable Element'),
                value: 'var',
            },
            {
                name: this.translateService.getString('Video Element'),
                value: 'video',
            },
            {
                name: this.translateService.getString('Word Break Opportunity Element'),
                value: 'wbr',
            },
        ];
    }
}
