import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParseResult } from 'ngx-papaparse';
import { NgxSelectOption } from 'ngx-select-ex';

export type UpdateType = { [name: string]: Record<string, string | number> };

@Component({
    selector: 'mon-form-cookie-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.less'],
})
export class FormCookieSetupComponent implements OnInit {
    @Input() data: ParseResult;
    @Output() update: EventEmitter<UpdateType> = new EventEmitter<UpdateType>();

    options: Record<string, string>[] = [];
    selected: Record<string, string | number> = { url: null, title: null };

    ngOnInit (): void {
        this.setupOptions();
        this.setupSelected();
    }

    onChange (event: NgxSelectOption): void {
        this.selected.url = event.value;
        this.update.emit({ selected: this.selected });
    }

    private setupOptions (): void {
        for (let i = 0; i < this.data.data[0].length; i++) {
            this.options.push({ name: this.data.data[0][i], value: i.toString() });
        }
    }

    private setupSelected (): void {
        for (let i = 0; i < this.options.length; i++) {
            switch (this.options[i].name.toLowerCase()) {
                case 'url':
                    this.selected.url = this.options[i].value;
                    break;
                case 'title':
                    this.selected.title = this.options[i].value;
                    break;
            }
        }
    }
}
