import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EmailValidatorService {
    // W3C pattern used for type="email"
    private emailPattern = `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$`;

    getEmailPattern (): string {
        return this.emailPattern;
    }
}
