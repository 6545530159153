import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterGlobals } from '@uirouter/core';
import { UIRouterModule } from '@uirouter/angular';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { SessionService } from '@monsido/core/session/session.service';
import { SuperAdminMenuType } from './constants/menu-item.constants';
import { takeUntil } from 'rxjs';
import { BaseDisposableMenuComponent } from '../base-disposable-menu/base-disposable-menu.component';

export type MenuItemType = {
    active?: boolean;
    activeRoutes?: string | string[];
    backend?: boolean;
    base: string;
    basic?: boolean;
    href: string;
    icon: string;
    open?: boolean;
    subs?: unknown[];
    title: string;
    tools?: boolean;
};

@Component({
    selector: 'mon-side-menu',
    standalone: true,
    imports: [CommonModule, UIRouterModule],
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent extends BaseDisposableMenuComponent implements OnInit {
    @Input() menu: MenuItemType[];
    @Input() showBackend?: boolean;
    @Input() agreement?: Agreement;

    private superAdminMenuTypeCollection = Object.values(SuperAdminMenuType).map(it => it.toLowerCase());

    constructor (private uiRouterGlobals: UIRouterGlobals, private sessionService: SessionService) {
        super();
    }

    ngOnInit (): void {
        if (Array.isArray(this.menu)) {
            this.menu.forEach((menuItem: MenuItemType) => {
                if (menuItem.href === this.uiRouterGlobals.current.name ||
                (menuItem.activeRoutes && menuItem.activeRoutes.indexOf(this.uiRouterGlobals.current.name) !== -1)) {
                    menuItem.active = true;

                    if (menuItem.subs) {
                        menuItem.open = true;
                    }
                }
            });

            this.sessionService.superAdmin$
                .pipe(takeUntil(this.destroy$))
                .subscribe((flag => {
                    if (!flag) {
                        this.filterMenuBySuperAdminScope();
                    }
                }));
        }
    }

    isSubentryActive (destination: string): boolean {
        return this.uiRouterGlobals.current.name.indexOf(destination) > -1;
    }

    menuClick (menuIndex: number): void {
        const menu = this.menu[menuIndex];

        if (menu.subs) {
            if (menu.open === true) {
                menu.open = false;
            } else {
                this.menu?.forEach((menuItem: MenuItemType) => {
                    menuItem.open = false;
                });
                menu.open = true;
            }
        }
    }

    private filterMenuBySuperAdminScope (): void {
        this.menu = this.menu.filter((menuItem: MenuItemType) => !this.superAdminMenuTypeCollection.includes(menuItem.base.toLowerCase()));
    }
}
