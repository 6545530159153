import { Injectable } from '@angular/core';
import { CoreAgreementService } from '@monsido/core/session/services/agreement.service';
import { SessionService } from '@monsido/core/session/session.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { OauthService } from '@monsido/oauth/oauth.service';
import { StateService, TargetState, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { ErrorHandlerService } from 'app/blocks/helpers/errorHandler/error-handler.service';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';

@Injectable({
    providedIn: 'root',
})
export class BasicService {

    constructor (
        private sessionService: SessionService,
        private coreAgreementService: CoreAgreementService,
        private monEventService: MonEventService,
        private errorHandlerService: ErrorHandlerService,
        private oauthService: OauthService,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private transitionService: TransitionService,
        private globalHelperService: GlobalHelperService,
    ) { }

    run (): void {
        this.redirectIfNotLoggedIn();
        this.registerTransitionOnExitAdminBase();
        this.registerTransitionOnStart();
    }

    private async validateEnvAccess (adminRoleRequired: boolean, env: string): Promise<TargetState> {
        if (
            !adminRoleRequired && !(await this.sessionService.hasAccessToResellerEnv(env))
        ) {
            return this.stateService.target('base.agreement_selector.index');
        }

        if (
            adminRoleRequired && !(await this.sessionService.hasAccessToAdminEnv(env))
        ) {
            return this.stateService.target('base.agreement_selector.index');
        }
    }

    private registerTransitionOnExitAdminBase (): void {
        this.transitionService.onExit({ exiting: 'base.admin' }, () => {
            return this.coreAgreementService.clearAgreement();
        });
    }

    private registerTransitionOnStart (): void {
        this.transitionService.onStart({}, (transition): Promise<TargetState> => {
            const adminRoleRequired = transition.to()?.data?.requiredRoles?.includes('admin');
            const skipEnvValidation = transition.to()?.data?.skipEnvValidation;
            const { env } = transition.params('to');

            return new Promise<TargetState>((resolve) => {
                this.monEventService.run('beforeUser')
                    .then(async () => {
                        if (!transition.to().data.anonymous_access) {
                            if (!this.sessionService.isLoggedIn()) {
                                this.oauthService.setRedirectState(transition.to().name, transition.params('to'));
                                this.globalHelperService.getWindowObject().location.href = this.sessionService.getAuthorizeUrl();
                                return resolve(null);
                            }
                            if (!this.sessionService.isUserLoaded()) {
                                this.sessionService.loadUser().then(async () => {
                                    if ('login.login' === transition.to().name) {
                                        return resolve(this.stateService.target('base.admin.customers.index'));
                                    }
                                    if (!skipEnvValidation) {
                                        return resolve(await this.validateEnvAccess(adminRoleRequired, env));
                                    }
                                }, this.errorHandlerService.standardMsg);
                            }
                            if (!skipEnvValidation && transition.to().data.requiredRoles !== undefined) {
                                return resolve(await this.validateEnvAccess(adminRoleRequired, env));
                            }
                        }
                        resolve(null);
                    })
                    .finally(() => {
                        this.monEventService.run('afterUser').then(() => {
                            this.monEventService.run('finishUser');
                        });
                    });
            });
        });
    }

    private redirectIfNotLoggedIn (): void {
        if (!this.uiRouterGlobals.transition.to().data.anonymous_access &&
        this.uiRouterGlobals.transition.to().name !== 'login.auth' &&
        !this.sessionService.isLoggedIn()) {
            this.uiRouterGlobals.transition.abort();
            this.oauthService.setRedirectState(this.uiRouterGlobals.transition.to().name, this.uiRouterGlobals.transition.params('to'));
            this.globalHelperService.getWindowObject().location.href = this.sessionService.getAuthorizeUrl();
        }
    }
}
