import { Injectable } from '@angular/core';

@Injectable()
export class GlobalHelperService {

    getWindowObject (): Window {
        return window;
    }

    /**
     * @param {String} obj JSON Object to stringify
     * @param {boolean|number|undefined} [pretty=2] If set to true, the JSON output will contain newlines and whitespace.
     *    If set to an integer, the JSON output will contain that many spaces per indentation.
     * @return {string|undefined} JSON-ified string representing `obj`.
     */
    stringify (obj: unknown, pretty?: boolean | number | undefined): string | undefined {
        if (this.isUndefined(obj)) {
            return undefined;
        }
        if (!this.isNumber(pretty)) {
            pretty = pretty ? 2 : undefined;
        }
        return JSON.stringify(obj, this.toJsonReplacer.bind(this), pretty as number);
    }

    isObject (val: unknown): boolean {
        return val !== null && typeof val === 'object' && typeof val !== 'undefined';
    }

    /**
     * This isValidHostname function comes https://github.com/miguelmota/is-valid-hostname
     * Check if a hostname is valid
     *
     * @param string hostname
     * @return boolean
     */

    isValidHostname (input: unknown): boolean {
        if (typeof input !== 'string') {
            return false;
        }

        let hostname = input as string;

        const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
        if (!validHostnameChars.test(hostname)) {
            return false;
        }

        if (hostname.endsWith('.')) {
            hostname = hostname.slice(0, hostname.length - 1);
        }

        if (hostname.length > 253) {
            return false;
        }

        const labels = hostname.split('.');

        const isValid = labels.every((label) => {
            const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

            const validLabel = validLabelChars.test(label) && label.length < 64 && !label.startsWith('-') && !label.endsWith('-');

            return validLabel;
        });

        return isValid;
    }

    /**
     * Check if a URL is valid
     *
     * @param string URL
     * @return boolean
     */

    isValidHttpUrl (string: string): boolean {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    /**
     * Check if an email is valid
     *
     * @param string email
     * @return boolean
     */

    isValidEmail (email: string): boolean {
        const emailRegex = new RegExp(
            '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
            '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
        );
        return Boolean(String(email).match(emailRegex));
    }

    static trackById (index: number): number {
        return index;
    }

    // PROTECTED

    /**
     *
     * @param {string} key
     * @param {any} value
     * @return {any}
     */
    toJsonReplacer (key: string, value: unknown): unknown {
        let val = value;

        if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') {
            val = undefined;
        } else if (this.isWindow(value)) {
            val = '$WINDOW';
        } else if (value && window.document === value) {
            val = '$DOCUMENT';
        } else if (this.isScope(value)) {
            val = '$SCOPE';
        }

        return val;
    }

    /**
     * Checks if `obj` is a window object.
     *
     * @private
     * @param {*} obj Object to check
     * @return {boolean} True if `obj` is a window obj.
     */
    isWindow (obj: unknown): boolean {
        return !!obj && (obj as Record<string, unknown>).window === obj;
    }

    isScope (obj: unknown): boolean {
        return Boolean(obj && (obj as Record<string, unknown>).$evalAsync && (obj as Record<string, unknown>).$watch);
    }

    isNumber (val: unknown): boolean {
        return typeof val === 'number';
    }

    isUndefined (val: unknown): boolean {
        return typeof val === 'undefined';
    }
}
