import { AccountInterface, AgreementInterface } from '@monsido/modules/models/api/interfaces/agreement.interface';

export class Agreement implements AgreementInterface {
    constructor (agreement?: AgreementInterface) {
        Object.assign(this, agreement);
    }

    account: AccountInterface;
    admin: boolean;
    settings: string;
    support: string;
    created_at: string;
    updated_at: string;
}
