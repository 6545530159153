import { BackendAdminApiClient } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { Injectable } from '@angular/core';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { createDefaultHttpParams } from '@monsido/modules/endpoints/api/support-api-client';
import { User } from '@monsido/modules/models/api/user';
import { Domain } from '@monsido/modules/models/api/domain';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { Params } from '@monsido/http/params';
import { Customer } from '@monsido/modules/models/api/customer';

@Injectable()
export class BackendCustomerRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    /** Customer **/
    get (customerId: number, params?: PlainHttpParams, headers?: HttpHeaders, urlPrefix?: string): Promise<Customer> {
        return this.adminClient.getPromise(`customers/${customerId}`, createDefaultHttpParams(params, headers), urlPrefix);
    }

    getAll (params?: PlainHttpParams, headers?: HttpHeaders, urlPrefix?: string): Promise<Customer[]> {
        return this.adminClient.getPromise(`customers`, createDefaultHttpParams(params, headers), urlPrefix);
    }

    /** Users **/
    getAllUsers (customerId: number, params?: PlainHttpParams): Promise<User[]> {
        return this.adminClient
            .getPromise(
                `customers/${customerId}/users`,
                createDefaultHttpParams(params, new HttpHeaders({ noParseSearch: 'true' })),
            )
            .then(BackendCustomerRepo.toUserModels);
    }

    getUser (customerId: number, userId: number): Promise<User> {
        return this.adminClient.getPromise(`customers/${customerId}/users/${userId}`).then(BackendCustomerRepo.toUserModel);
    }

    createUser (customerId: number, user: User): Promise<User> {
        return this.adminClient.postPromise(`customers/${customerId}/users`, user).then(BackendCustomerRepo.toUserModel);
    }

    updateUser (customerId: number, user: User): Promise<User> {
        return this.adminClient.patchPromise(`customers/${customerId}/users/${user.id}`, user).then(BackendCustomerRepo.toUserModel);
    }

    destroyUser (customerId: number, userId: number): Promise<void> {
        return this.adminClient.deletePromise(`customers/${customerId}/users/${userId}`);
    }

    /** Domains **/
    getDomain (customerId: number, domainId: number, options: Params, urlPrefix?: string): Promise<Domain> {
        return this.adminClient
            .getPromise('customers/' + customerId + '/domains/' + domainId, options, urlPrefix)
            .then(BackendCustomerRepo.toDomainModel);
    }

    getAllDomains (customerId: number, params?: PlainHttpParams): Promise<Domain[]> {
        return this.adminClient
            .getPromise(
                `customers/${customerId}/domains`,
                createDefaultHttpParams(params, new HttpHeaders({ noParseSearch: 'true' })),
            )
            .then(BackendCustomerRepo.toDomainModels);
    }

    importSpellingErrors (customerId: number, params: PlainHttpParams): Promise<JobInterface> {
        return this.adminClient.postPromise(`customers/${customerId}/import/confirmed-spelling-errors`, params);
    }

    importDictionary (customerId: number, params: PlainHttpParams): Promise<JobInterface> {
        return this.adminClient.postPromise(`customers/${customerId}/import/dictionary`, params);
    }

    // Protected

    private static toUserModels (models: Array<User>): Array<User> {
        for (let i = 0; i < models.length; i++) {
            models[i] = BackendCustomerRepo.toUserModel(models[i]);
        }
        return models;
    }

    private static toDomainModels (models: Array<Domain>): Array<Domain> {
        for (let i = 0; i < models.length; i++) {
            models[i] = BackendCustomerRepo.toDomainModel(models[i]);
        }
        return models;
    }

    private static toCustomerModels (models: Array<Customer>): Array<Customer> {
        for (let i = 0; i < models.length; i++) {
            models[i] = BackendCustomerRepo.toCustomerModel(models[i]);
        }
        return models;
    }

    private static toDomainModel (model: Domain): Domain {
        return new Domain(model);
    }

    private static toUserModel (model: User): User {
        return new User(model);
    }

    private static toCustomerModel (model: Customer): Customer {
        return new Customer(model);
    }
}
