<mon-page-header [monIcon]="['CRAWLERS', 'ICON']"
                 header="Crawlers"
                 subHeader="{{'Overview of current crawlers. Current scale: '}}  {{ scaleStatus.current_scale }}">
    <div class="d-flex justify-content-end">
        <button class="btn btn-secondary mr-1" type="button" (click)="scaleUp()" translate>Scale-up</button>
        <button class="btn btn-secondary mr-1" type="button" (click)="scaleDown()" translate>Scale-down</button>
        <button class="btn btn-secondary mr-1" type="button" (click)="pauseCrawlers()" translate>Pause</button>
        <button class="btn btn-secondary mr-1" type="button" (click)="resumeCrawlers()" translate>Resume</button>
    </div>
</mon-page-header>
<div class="card monsido-panel">
    <div class="card-body">
        <mon-table-container [collection]="crawlers"
                             [loading]="loading"
                             class="table-vertial-align">
                <div class="table-container-body">
                    <table class="table table-fixed table-hover">
                        <thead>
                        <tr>
                            <th table-grid-host translate>Host</th>
                            <th table-grid-pid translate>Pid</th>
                            <th table-grid-state translate class="text-center">State</th>
                            <th table-grid-paused translate class="text-right">Running</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let crawler of crawlers">
                            <td>{{ crawler.host }}</td>
                            <td>{{ crawler.pid }}</td>
                            <td class="text-center">
                                <mon-icon title="{{'Up' | translate }}"
                                          class="text-success"
                                          [icon]="['CRAWLERS', 'STATE_UP']"
                                          *ngIf="crawler.state === 'UP'">
                                </mon-icon>
                                <mon-icon title="{{'Down' | translate }}"
                                          class="text-danger"
                                          [icon]="['CRAWLERS', 'STATE_DOWN']"
                                          *ngIf="crawler.state === 'DOWN'">
                                </mon-icon>
                            </td>
                            <td class="text-right">
                                <mon-icon title="{{'Paused' | translate }}"
                                          class="text-warning"
                                          [icon]="['CRAWLERS', 'PAUSED']"
                                          *ngIf="crawler.paused">
                                </mon-icon>
                                <mon-icon title="{{'Running' | translate }}"
                                          class="text-success"
                                          [icon]="['CRAWLERS', 'NOT_PAUSED']"
                                          *ngIf="!crawler.paused">
                                </mon-icon>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
        </mon-table-container>
    </div>
</div>
