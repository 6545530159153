export enum EventsTypeEnum {
    setAPI = 'setAPI',
    beforeLoadUser = 'beforeLoadUser',
    afterBaseLayout = 'afterBaseLayout',
    exitAccount = 'exitAccount',
    setAgreement = 'setAgreement',
    clearAgreement = 'clearAgreement',
    closeALLDialogs = 'closeALLDialogs',
    closeALLOverlayDialogs = 'closeALLOverlayDialogs',
    beforeLogin = 'beforeLogin',
    finishLogin = 'finishLogin',
    beforeUser = 'beforeUser',
    finishUser = 'finishUser',
    loadDialog = 'loadDialog',
    loadNg2Dialog = 'loadNg2Dialog',
    logout = 'logout',
    signOut = 'signOut',
    setSessionAvailableAdminEnvs = 'setSessionAvailableAdminEnvs',
    setSessionAvailableResellerEnvs = 'setSessionAvailableResellerEnvs',
    loadResellerAgreements = 'loadResellerAgreements',
    loadResellerAgreementsDone = 'loadResellerAgreementsDone',
    afterUser = 'afterUser',
    newAccessToken = 'newAccessToken',
    PuppeteerBrowserServiceInUsed = 'PuppeteerBrowserServiceInUsed',
    LegacyBrowserServiceInUsed = 'LegacyBrowserServiceInUsed',
}

export type eventsType = keyof typeof EventsTypeEnum;
