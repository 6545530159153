import {
    CustomerBranding, CustomerEula,
    CustomerInterface,
    CustomerReseller,
    CustomerSettings,
    CustomerSubscription,
} from '@monsido/modules/models/api/interfaces/customer.interface';
import { UniqueTraitsInterface } from '@monsido/modules/models/api/interfaces/uniqueTraits.interface';

export class Customer implements CustomerInterface {
    constructor (customer?: CustomerInterface) {
        Object.assign(this, customer);
    }
    id: number;
    name: string;
    attention?: string | null;
    address?: string | null;
    zipcode?: string | null;
    city?: string | null;
    state?: string | null;
    country: string;
    vat_number?: string | null;
    phone_number?: string | null;
    timezone?: string | null;
    salesforce_account_id?: string | null;
    source?: null;
    settings: CustomerSettings;
    subscription: CustomerSubscription;
    account_suspended: boolean;
    branding: CustomerBranding;
    plan_traits: UniqueTraitsInterface;
    plan_type?: string | null;
    reseller: CustomerReseller;
    domains_count?: number | null;
    users_count?: number | null;
    vertical?: string | null;
    created_at: string;
    updated_at: string;
    deleted_at?: null;
    html_pages_count: number;
    documents_count: number;
    eula: CustomerEula;
    legacy_compliance?: boolean;
    labels?: { id: number }[];
}
