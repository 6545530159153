import { Component, OnInit } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { TransitionService, UrlService } from '@uirouter/core';
import { ParamService } from '@monsido/core/param/param.service';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';
import { BackendAdminCrawlQueueService } from './crawl-queue.service';
import { CRAWL } from '@monsido/core/constants/crawl.constant';
import { SortType } from '@monsido/angular-shared-components';
import { TranslateService } from 'app/services/translate/translate.service';

interface CustomerList {
    customer_id: number;
    name: string;
    count: number;
}


@Component({
    selector: 'mon-backend-admin-crawl-queue',
    templateUrl: 'crawl-queue.html',
    styleUrls: ['crawl-queue.scss'],
})
export class BackendAdminCrawlQueueComponent extends BaseApiComponent implements OnInit {
    search: string = '';
    activeTab: string;
    customerList: CustomerList[] = [];
    crawls: CrawlQueue[] = [];
    orgCrawls: CrawlQueue[] = [];
    loading: boolean;
    timeAgoPromise: ReturnType<Window['setTimeout']> | unknown;
    timeSince: number;
    lastTimeout: unknown;
    timerPromise: ReturnType<Window['setTimeout']> | unknown;
    sortedBy: string | null = null;
    direction: SortType = null;
    tabs: Record<string, string | null>[] = [
        {
            name: this.translateService.getString('All'),
            value: CRAWL.TAB.ALL,
        },
        {
            name: this.translateService.getString('Active'),
            value: CRAWL.TAB.ACTIVE,
        },
        {
            name: this.translateService.getString('On hold'),
            value: CRAWL.TAB.ON_HOLD,
        },
        {
            name: this.translateService.getString('In Retry'),
            value: CRAWL.TAB.RETRY,
        },
        {
            name: this.translateService.getString('Queued'),
            value: CRAWL.TAB.QUEUED,
        },
        {
            name: this.translateService.getString('Support'),
            value: CRAWL.TAB.SUPPORT,
        },
        {
            name: this.translateService.getString('Accessibility Problem'),
            value: CRAWL.TAB.ACCESSIBILITY_PROBLEM,
        },
    ];
    unsubscribeTransition: unknown;
    readonly CRAWL = CRAWL;

    constructor (
        private location: UrlService,
        private paramService: ParamService,
        private translateService: TranslateService,
        private backendAdminCrawlQueueService: BackendAdminCrawlQueueService,
        private transitionService: TransitionService,
    ) {
        super(location);
    }

    ngOnInit (): void {
        this.urlParams = [
            {
                name: 'search',
                type: 'string',
                default: null,
                propertyName: 'search',
            },
            {
                name: 'tab',
                type: 'string',
                default: CRAWL.TAB.ACTIVE,
                propertyName: 'activeTab',
            },
        ];
        this.lastTimeout = new Date();
        this.startAutoReload();
        this.loadLocations();
        this.timeAgo();
        this.unsubscribeTransition = this.transitionService.onSuccess(
            { retained: 'base.backend_admin.crawl_queue.index' },
            (transition) => {
                const { search, tab } = transition.targetState().params();
                if (tab && (this.activeTab !== tab || this.search !== search)) {
                    this.activeTab = tab;
                    this.search = search;
                    this.getPage();
                }
            });
    }

    onSort ({ direction, by }: { direction: SortType, by: string }): void {
        if (direction !== null) {
            const sorter = direction === 'asc' ? 1 : -1;
            if (by === 'queued_at') {
                this.crawls.sort((a: CrawlQueue, b: CrawlQueue) => {
                    const aDateValue = new Date(a.queued_at).getTime();
                    const bDateValue = new Date(b.queued_at).getTime();
                    return aDateValue === bDateValue ? 0 : aDateValue < bDateValue ? sorter : -sorter;
                });

            }
        }
        this.direction = direction;
        this.sortedBy = by;
    }

    timeAgo (): void {
        this.timeAgoPromise = setTimeout(() => {
            const now: Date = new Date();
            this.timeSince = Math.round(((now as unknown as number) - (this.lastTimeout as unknown as number)) / 1000);
            this.timeAgo();
        }, 1000);
    }

    loadLocations (): void {
        this.search = this.location.search().search || null;
        this.activeTab = this.location.search().tab || CRAWL.TAB.ALL;
        this.getPage();
    }

    goToDomain (domain: Domain): void {
        this.backendAdminCrawlQueueService.goToDomain(domain);
    }

    ngOnDestroy (): void {
        clearTimeout(this.timerPromise as ReturnType<Window['setTimeout']>);
        clearTimeout(this.timeAgoPromise as ReturnType<Window['setTimeout']>);
        if (typeof this.unsubscribeTransition === 'function') {
            this.unsubscribeTransition();
        }
    }

    getPage (noSpinner?: boolean): void {
        this.setQueryParams();
        this.getQueue(noSpinner);
    }

    getQueue (noSpinner?: boolean): void {
        const params: { [key: string]: string } = {};

        if (this.activeTab !== CRAWL.TAB.ALL && this.activeTab !== CRAWL.TAB.ACTIVE && this.activeTab !== CRAWL.TAB.QUEUED) {
            params.type = this.activeTab;
        }

        this.loading = !noSpinner;
        this.backendAdminCrawlQueueService.getAllCrawlQueue(params).then(
            (data) => {
                this.orgCrawls = data;
                this.processCrawls();
                this.loading = false;
            },
            (res) => {
                if (!noSpinner) {
                    this.loading = res.status === -1 && res.xhrStatus === 'abort';
                }
            },
        );
    }

    private processCrawls (): void {
        this.setCrawls();
        this.onSort({ direction: this.direction, by: this.sortedBy });
    }

    startAutoReload (): void {
        this.timerPromise = setTimeout(() => {
            this.lastTimeout = new Date();
            this.getPage(true);
            this.startAutoReload();
        }, 30000);
    }

    stopCrawl (crawl: CrawlQueue): void {
        this.backendAdminCrawlQueueService.stopCrawl(crawl.domain.id).then(
            () => {
                this.getPage();
            },
            () => {},
        );
    }

    onSearch (search: string): void {
        if (search === null || search === undefined || search === '') {
            this.search = null;
        } else {
            this.search = search;
        }
        this.setQueryParams();
        this.processCrawls();
    }

    tabChange (tab: string): void {
        if (tab !== this.activeTab) {
            this.activeTab = tab;
            this.getPage();
        }
    }

    openCrawl (e: MouseEvent, crawl: CrawlQueue): void {
        e.preventDefault();
        this.backendAdminCrawlQueueService.openDialog(crawl, () => {
            this.getPage();
        });
    }

    customerCounts (): void {
        for (let i = 0; i < this.crawls.length; i++) {
            const crawl = this.crawls[i];
            const crawlCustomerId = crawl.customer?.id;
            if (crawlCustomerId == null) {
                return;
            }
            const entry = this.customerList.find((item: CustomerList) => {
                return item.customer_id === crawlCustomerId;
            });

            if (entry) {
                entry.count++;
            } else {
                this.customerList.push({
                    customer_id: crawlCustomerId,
                    name: this.crawls[i].customer.name,
                    count: 1,
                });
            }

        }
    }

    private setQueryParams (): void {
        this.paramService.setParams({
            tab: this.activeTab,
            search: this.search,
        });
    }

    private setCrawls (): void {
        const crawls = this.backendAdminCrawlQueueService.getCrawls(this.orgCrawls, this.search);
        this.crawls = this.filterCrawlsByTab(crawls);
        this.customerCounts();
    }

    private filterCrawlsByTab (crawls: CrawlQueue[]): CrawlQueue[] {
        if (this.activeTab === CRAWL.TAB.QUEUED) {
            return crawls.filter((element) => {
                return element.state === CRAWL.STATE.QUEUED;
            });
        }

        if (this.activeTab === CRAWL.TAB.ACTIVE) {
            return crawls.filter((element) => {
                return (
                    element.state !== CRAWL.STATE.QUEUED &&
                    element.state !== CRAWL.STATE.RETRY &&
                    element.state !== CRAWL.STATE.ON_HOLD &&
                    element.state !== CRAWL.STATE.SUPPORT &&
                    element.state !== CRAWL.STATE.ACCESSIBILITY_PROBLEM
                );
            });
        } else if (this.activeTab === CRAWL.TAB.ON_HOLD) {
            return crawls.filter((element) => {
                return element.state === CRAWL.STATE.ON_HOLD;
            });
        } else if (this.activeTab === CRAWL.TAB.RETRY) {
            return crawls.filter((element) => {
                return element.state === CRAWL.STATE.RETRY;
            });
        } else if (this.activeTab === CRAWL.TAB.SUPPORT) {
            return crawls.filter((element) => {
                return element.state === CRAWL.STATE.SUPPORT;
            });
        } else if (this.activeTab === CRAWL.TAB.ACCESSIBILITY_PROBLEM) {
            return crawls.filter((element) => {
                return element.state === CRAWL.STATE.ACCESSIBILITY_PROBLEM;
            });
        }
        return crawls;
    }
}
