import { Injectable } from '@angular/core';
import { CollectionInterface } from '@monsido/angular-shared-components';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { map, Observable, share, Subject, switchMap, tap } from 'rxjs';
import { LinkExcludeModel } from '../constraints-and-excludes-models/link-exclude.model';
import { PathConstraintsModel } from '../constraints-and-excludes-models/path-constraint.model';
import { LinkExcludedInterface, PathConstraintInterface } from '../constraints-and-excludes.interface';
import { ConstraintsAndExcludesInterface, RequestParamsType, RequestType } from './constraints-and-excludes.interface';

const constraintsApiPath = 'path_constraints';
const linkExcludesApiPath = 'link_excludes';

@Injectable({
    providedIn: 'root',
})
export class ConstraintsAndExcludesService implements ConstraintsAndExcludesInterface {
    private readonly _loadingPathConstraitsProgress = new Subject<boolean>();
    private readonly _loadingLinksExcludedProgress = new Subject<boolean>();

    private readonly loadPathConstraits$ = new Subject<RequestType>();
    private readonly loadLinksExcluded$ = new Subject<RequestType>();
    private readonly responsePathConstraits$ = this.loadPathConstraits$.pipe(
        tap(() => {
            this._loadingPathConstraitsProgress.next(true);
        }),
        switchMap((request) =>
            this.apiClient.getObservable(request.url, request.options) as unknown as
            Observable<CollectionInterface<PathConstraintInterface>>,
        ),
        share(),
    );
    private readonly responseLinksExcluded$ = this.loadLinksExcluded$.pipe(
        tap(() => {
            this._loadingLinksExcludedProgress.next(true);
        }),
        switchMap((request) => this.apiClient.getObservable(request.url, request.options) as unknown as Observable<CollectionInterface<LinkExcludedInterface>>),
        share(),
    );

    readonly loadingPathConstraitsProgress$ = this._loadingPathConstraitsProgress.asObservable();
    readonly loadingLinksExcludedProgress$ = this._loadingLinksExcludedProgress.asObservable();

    readonly pathConstraits$ = this.responsePathConstraits$.pipe(
        map((collection: CollectionInterface<PathConstraintInterface>) => {
            // NB! The collection is not an array,
            // but an array-like object with some extra properties (e.g. pageSize)
            collection.forEach((entry, i) => {
                collection[i] = new PathConstraintsModel(entry);
            });
            return collection;
        }),
        tap(() => {
            this._loadingPathConstraitsProgress.next(false);
        }),
    );
    readonly linksExcluded$ = this.responseLinksExcluded$.pipe(
        map((collection: CollectionInterface<LinkExcludedInterface>) => {
            // NB! The collection is not an array,
            // but an array-like object with some extra properties (e.g. pageSize)
            collection.forEach((entry, i) => {
                collection[i] = new LinkExcludeModel(entry);
            });
            return collection;
        }),
        tap(() => {
            this._loadingLinksExcludedProgress.next(false);
        }),
    );

    constructor (
        private apiClient: ApiClient,
    ) { }

    loadConstraints (params?: RequestParamsType): void {
        this.loadPathConstraits$.next({
            url: constraintsApiPath,
            options: {
                params,
            },
        });
    }

    loadLinksExcluded (params?: RequestParamsType): void {
        this.loadLinksExcluded$.next({
            url: linkExcludesApiPath,
            options: {
                params,
            },
        });
    }

    postEntry (entry: LinkExcludedInterface | PathConstraintInterface, startLoadingIndicator = false): Observable<unknown> {
        const apiPath = this.getApiPath(entry);
        if (startLoadingIndicator) {
            this.setLoaderStatus(apiPath, true);
        }
        return this.apiClient.postObservable(apiPath, entry);
    }

    patchEntry (entry: LinkExcludedInterface | PathConstraintInterface, startLoadingIndicator = false): Observable<unknown> {
        const apiPath = this.getApiPath(entry);
        if (startLoadingIndicator) {
            this.setLoaderStatus(apiPath, true);
        }
        return this.apiClient.patchObservable(`${this.getApiPath(entry)}/${entry.id}`, entry);
    }

    deleteEntry (entry: LinkExcludedInterface | PathConstraintInterface, startLoadingIndicator = false): Observable<unknown> {
        const apiPath = this.getApiPath(entry);
        if (startLoadingIndicator) {
            this.setLoaderStatus(apiPath, true);
        }
        return this.apiClient.deleteObservable(`${this.getApiPath(entry)}/${entry.id}`);
    }

    private getApiPath (entry: LinkExcludedInterface | PathConstraintInterface): string {
        return entry instanceof PathConstraintsModel ? constraintsApiPath : linkExcludesApiPath;
    }
    private setLoaderStatus (apiPath: string, status: boolean): void {
        if (apiPath === constraintsApiPath) {
            this._loadingPathConstraitsProgress.next(status);
        } else if (apiPath === linkExcludesApiPath) {
            this._loadingLinksExcludedProgress.next(status);
        }
    }
}
