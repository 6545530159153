import { Injectable } from '@angular/core';
import { CoreAgreementService } from '@monsido/core/session/services/agreement.service';
import { SessionService } from '@monsido/core/session/session.service';
import { AgreementsRepo } from '@monsido/modules/endpoints/api/admin/agreements.repo';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { Me } from '@monsido/modules/models/api/me';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { StateService, TransitionService } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class AdminRouteService {

    constructor (
        private monEventService: MonEventService,
        private state: StateService,
        private sessionService: SessionService,
        private transitionService: TransitionService,
        private coreAgreementService: CoreAgreementService,
        private agreementsRepo: AgreementsRepo,
    ) {
        this.transitionService.onEnter({ to: 'login.auth' }, () => {
            this.monEventService.run('afterLogin').then(() => {
                return this.monEventService.run('finishLogin');
            });
        });
        this.transitionService.onExit({ from: 'login.auth' }, () => {
            this.monEventService.run('exitLogin');
        });
    }

    async init (paramsAccountId: string): Promise<boolean> {
        await this.monEventService.run('beforeAccount');

        // eslint-disable-next-line no-async-promise-executor
        const promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            const setAgreement = async (agreement: Agreement, agreements: Agreement[]): Promise<void> => {
                await this.coreAgreementService.setAgreement(agreement);
                this.coreAgreementService.setAgreements(agreements);
                resolve(true);
            };

            let user: Me | void;
            try {
                user = await this.sessionService.getUser();
            } catch {
                reject(new Error('Can\'t fetch user'));
            }

            const accountId = Number(paramsAccountId);

            if (user && this.sessionService.account && this.sessionService.account.id === accountId) {
                resolve(true);
            } else {
                let selected = false;
                const agreements = await this.agreementsRepo.getAll();
                for (const agreement of agreements) {
                    if (agreement.account.id === accountId) {
                        selected = true;
                        await setAgreement(agreement, agreements);
                        break;
                    }
                }
                if (selected === false) {
                    reject(new Error('Can\'t find account'));
                }
            }
        });

        promise.catch(async () => {
            await this.state.go('base.agreement_selector.index');
        });

        return promise;
    }

}
