import { Injectable } from '@angular/core';
import { SupportApiClient } from '@monsido/modules/endpoints/api/support-api-client';
import { Cookie } from '@monsido/modules/models/support/cookie';

@Injectable()
export class CookiesRepo {
    constructor (private apiClient: SupportApiClient) {}

    getAll (params: Record<string, string | number>): Promise<Cookie[]> {
        params = params || ({} as Record<string, string | number>);
        return this.apiClient.getPromise('admin/cookies', { params }).then(this.toModels.bind(this));
    }

    get (id: number): Promise<Cookie> {
        return this.apiClient.getPromise(`admin/cookies/${id}`).then(this.toModel.bind(this));
    }

    create (cookie: Cookie): Promise<Cookie> {
        return this.apiClient.postPromise('admin/cookies', cookie).then(this.toModel.bind(this));
    }

    update (cookie: Cookie): Promise<Cookie> {
        return this.apiClient.patchPromise('admin/cookies/' + cookie.id, cookie).then(this.toModel.bind(this));
    }

    destroy (id: number): Promise<void> {
        return this.apiClient.deletePromise('admin/cookies/' + id);
    }

    private toModels (models: Cookie[]): Cookie[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: Cookie): Cookie {
        return new Cookie(model);
    }
}
