import {
    AdditionalInternalUrls,
    CmsExtra,
    CrawlHistory,
    CrawlStatus,
    DomainGroupDataType,
    DomainDataType,
    Features,
    LinkExcludes,
    PageAssistSettings,
    PathConstraints,
    Scan,
    Settings,
} from 'types/domain';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { PAGE_FIX_DOMAIN_SETTINGS_MODULE } from '@monsido/core/constants/page-fix-domain-settings.constant';
import { DomainUsersEntity } from '@monsido/modules/models/api/interfaces/user.interface';
import { Customer } from '@monsido/modules/models/api/customer';
import { DomainBaseModel } from 'app/forms/import-users/steps/domains/domain-import/domain-base.model';
import { getSelectOptionsValues, WEEK_DAY_OPTIONS } from '@monsido/core/constants/select-options.constant';

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

const weekDayOptions = getSelectOptionsValues(WEEK_DAY_OPTIONS);
const defaultCmsExtra: CmsExtra = {
    base_url: null,
    url: null,
    subdomain: null,
    language_id: null,
    areadynamicpage: null,
    newspage: null,
    employeepage: null,
    episerver_path: null,
    contentid_tag: null,
    language_tag: null,
    path_template: null,
    special_key: null,
    url_postfix: null,
    url_structure: null,
    version: null,
    prefix_path: null,
    cms_subdomain: null,
    default_language: null,
    site: null,
};
const defaultDomainModelValue: DeepPartial<DomainDataType> = {
    active: false,
    domain_users: [],
    scan: {
        case_sensitive: true,
        connections_per_minute: 60,
        day: 'any',
        time: moment().set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
            .format('HH:mm:ss'),
        use_lang_attribute: true,
    },
    features: {
        statistics: false,
        accessibility: null,
        readability_test: null,
        accessibility_errors_only: false,
    },
    additional_internal_urls: [],
    settings: {
        scripts: {
            page_correct: {
                enabled: false,
                admin_only: false,
                enabled_checks: [],
            },
        },
    },
    link_excludes: [],
    path_constraints: [],
    cms_extra: defaultCmsExtra,
};

export class Domain extends DomainBaseModel {
    accessibility_source_code_excludes?: string[];
    active: boolean;
    additional_internal_urls?: AdditionalInternalUrls[];
    cms: string;
    cms_extra: CmsExtra;
    crawled_pages: number;
    crawl_status: CrawlStatus;
    crawl_history: CrawlHistory;
    customer: Customer;
    customer_id: number;
    domain_groups?: DomainGroupDataType[];
    domain_users?: DomainUsersEntity[];
    excluded_ips?: string;
    features: Features;
    labels?: string[] | null;
    language: string;
    last_scan: string;
    link_excludes?: LinkExcludes[];
    notes?: string;
    page_assist_settings?: PageAssistSettings;
    path_constraints?: PathConstraints[];
    running_groups: boolean;
    scan: Scan;
    settings: Settings;
    source_code_excludes?: string[];
    timezone?: string;
    token: string;
    url: string;

    constructor (domain?: DomainDataType) {
        super(domain);

        Object.assign(this, defaultDomainModelValue);
        this.cms_extra = this.getDefaultCmsExtra();

        if (domain) {
            this.accessibility_source_code_excludes = domain.accessibility_source_code_excludes ?? this.accessibility_source_code_excludes;
            this.active = domain.active ?? this.active;
            this.additional_internal_urls = domain.additional_internal_urls ?? this.additional_internal_urls;
            this.cms = domain.cms ?? this.cms;
            this.cms_extra = domain.cms_extra ?? this.cms_extra;
            this.crawled_pages = domain.crawled_pages ?? this.crawled_pages;
            this.crawl_status = domain.crawl_status ?? this.crawl_status;
            this.crawl_history = domain.crawl_history ?? this.crawl_history;
            this.customer = domain.customer ?? this.customer;
            this.customer_id = domain.customer_id ?? this.customer_id;
            this.domain_groups = domain.domain_groups ?? this.domain_groups;
            this.domain_users = domain.domain_users ?? this.domain_users;
            this.excluded_ips = domain.excluded_ips ?? this.excluded_ips;
            this.features = domain.features ?? this.features;
            this.labels = domain.labels ?? this.labels;
            this.language = domain.language ?? this.language;
            this.last_scan = domain.last_scan ?? this.last_scan;
            this.link_excludes = domain.link_excludes ?? this.link_excludes;
            this.notes = domain.notes ?? this.notes;
            this.page_assist_settings = domain.page_assist_settings ?? this.page_assist_settings;
            this.path_constraints = domain.path_constraints ?? this.path_constraints;
            this.running_groups = domain.running_groups ?? this.running_groups;
            this.scan = domain.scan ?? this.scan;
            this.settings = domain.settings ?? this.settings;
            this.source_code_excludes = domain.source_code_excludes ?? this.source_code_excludes;
            this.timezone = domain.timezone ?? this.timezone;
            this.token = domain.token ?? this.token;
            this.url = domain.url ?? this.url;
        }

        if (this.last_scan) {
            this.last_scan = moment(this.last_scan, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString();
        }

        if (this.id > 0) {
            const origTime = this.scan.time;
            const utcTime = moment(this.scan.time, 'HH:mm:ss');
            const selectedTime = moment(this.scan.time, 'HH:mm:ss').add(this.utcOffset, 'minutes');
            this.scan.time = selectedTime.format('HH:mm:ss');
            // Converting day from UTC to current timezone for displaying in the view: Shift one day forward if there is day difference
            this.scan.day = this.getCurrentScanDay(origTime, this.scan.day, this.calculateDayDiffBetweenTimes(selectedTime, utcTime) * -1);

            this.updated_at = moment(this.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
            this.created_at = moment(this.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        }

        if (this.settings) {
            if (!this.settings.scripts.page_correct.enabled_checks || this.settings.scripts.page_correct.enabled_checks.length === 0) {
                this.settings.scripts.page_correct.enabled_checks = [
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.LINK,
                    PAGE_FIX_DOMAIN_SETTINGS_MODULE.CUSTOM,
                ];
            }
        }
    }


    get utcOffset (): number {
        return moment().utcOffset();
    }

    /**
     *  Return scan day + time in UTC to submit to API request
     */
    getScanDayTimeUTC (): { day: string, time: string } {
        const selectedTime = moment(this.scan.time, 'HH:mm:ss');
        const utcTime = moment(selectedTime).subtract(this.utcOffset, 'minutes');
        // Converting day from current timezone to UTC for submitting to server: Shift one day backward if there is day difference
        const day = this.getScanDayUTC(this.calculateDayDiffBetweenTimes(selectedTime, utcTime));

        return {
            day,
            time: this.getScanTimeUTC(),
        };
    }

    private getScanDayUTC (dayOffset = 0): string {
        if (this.scan.day !== 'any') {
            return this.getDayFromList(this.scan.time, this.scan.day, dayOffset);
        }
        return this.scan.day;
    }

    getScanTimeUTC (): string {
        return moment(this.scan.time, 'HH:mm:ss').subtract(this.utcOffset, 'minutes')
            .format('HH:mm:ss');
    }

    getDefaultCmsExtra (): CmsExtra {
        return defaultCmsExtra;
    }

    clone (): DomainDataType {
        const clone = cloneDeep(this);
        clone.domain_users.forEach(function (user) {
            delete user.id;
            return user;
        });
        clone.link_excludes = clone.link_excludes.map(function (exclude) {
            delete exclude.id;
            return exclude;
        });
        clone.path_constraints = clone.path_constraints.map(function (constraint) {
            delete constraint.id;
            return constraint;
        });
        delete clone.crawl_history;
        delete clone.updated_at;
        delete clone.created_at;
        delete clone.crawl_status;
        delete clone.id;
        delete clone.url;
        delete clone.title;
        clone.domain_groups = [];
        return clone;
    }

    private getCurrentScanDay (time: string, day: string, dayOffset = 0): string {
        if (day !== 'any' && day !== null) {
            return this.getDayFromList(time ,day, dayOffset);
        }
        return day || 'any';
    }

    private getDayFromList (time: string, day:string, dayOffset = 0): string {
        let weekDayNo = moment(time, 'HH:mm:ss')
            .locale('en')
            .utcOffset(0) // it is crucial to set offset before day
            .day(day)
            .isoWeekday();

        if (dayOffset !== 0) {
            weekDayNo = weekDayNo - dayOffset;
            if (weekDayNo < 1) {
                weekDayNo = weekDayOptions.length - 1;
            }
            if (weekDayNo > weekDayOptions.length - 1) {
                weekDayNo = 1;
            }
        }
        return weekDayOptions[weekDayNo];
    }

    private calculateDayDiffBetweenTimes (a: moment.Moment, b: moment.Moment): number {
        return a.clone().startOf('day')
            .diff(b.clone().startOf('day'), 'day');
    }
}
