import { Injectable } from '@angular/core';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@Injectable()
export class MonRunLoadingService {
    constructor (private ng2MonEventsService: MonEventService, private monLoading: MonLoadingService) {}

    initializeApp (): Promise<void> {
        return new Promise<void>((resolve) => {
            this.ng2MonEventsService.addListener('beforeLogin', (index: number): number => {
                this.monLoading.show();
                return index;
            });

            this.ng2MonEventsService.addListener('finishLogin', (index: number): number => {
                this.monLoading.hide();
                return index;
            });

            resolve();
        });
    }
}
