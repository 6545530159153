import { Component, Input, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PERFORMANCE } from '@monsido/core/constants/performance.constant';
import { PLAN } from '@monsido/core/constants/plan.constant';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { Plan } from '@monsido/modules/models/api/plan';
import { ActiveDialog, ToastService } from '@monsido/angular-shared-components';
import { CloneService } from '@monsido/modules/utils/clone/clone.service';
import { DiffService } from '@monsido/modules/utils/diff/diff.service';
import { PlanInterface } from '@monsido/modules/models/api/interfaces/plan.interface';
import { BaseForm } from '@monsido/forms/base-form';
import { PLAN_TRAITS } from '@monsido/core/constants/plan-traits.constant';
import { PlanTraitService } from '@monsido/services/plan-trait/plan-trait.service';
import { SelectOption } from '@monsido/core/constants/select-options.constant';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-plan',
    templateUrl: 'plan.html',
    styleUrls: ['./plan.scss'],
})
export class FormBackendAdminPlanComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() plan: Plan = new Plan();
    @ViewChild('planForm', { static: false }) planForm: NgForm;
    orgPlan: Plan;
    localPlan: Plan;
    prices: string[];
    groups: Array<Record<string, string | number>>;
    types: Array<Record<string, string | number>>;
    performanceFrequencies: Array<Record<string, string | number>>;
    intervalOptions: Array<Record<string, string | number>>;
    saving: boolean = false;
    scanFrequenciesOptions: SelectOption[];
    spellCheckEngineOptions: Array<Record<string, string | number | boolean>>;

    form: NgForm;
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private backendPlansRepo: BackendPlansRepo,
        private cdref: ChangeDetectorRef,
        private planTraitService: PlanTraitService,
    ) {
        super();
    }

    ngOnInit (): void {
        this.prices = PLAN.PRICES;
        this.groups = PLAN.PLAN_GROUPS;
        this.types = PLAN.PLAN_TYPES;
        this.intervalOptions = PLAN.INTERVAL_OPTIONS;
        this.performanceFrequencies = PERFORMANCE.PERFORMANCE_FREQUENCIES;
        this.saving = false;
        this.localPlan = CloneService.clone(this.plan);
        this.orgPlan = CloneService.clone(this.plan);
        if (!this.localPlan.id) {
            this.localPlan = new Plan(this.localPlan);
        }

        this.spellCheckEngineOptions = this.planTraitService.getSpellCheckOptions();
        this.scanFrequenciesOptions = PLAN_TRAITS.SCAN_FREQUENIES;

        this.prices.forEach((month) => {
            if (this.localPlan.price[month] === undefined) {
                this.localPlan.price[month] = {
                    dkk: 0,
                    usd: 0,
                };
            }
        });
    }

    ngAfterViewInit (): void {
        if (this.planForm) {
            setTimeout(() => this.resetFormState(this.planForm));
            this.activeDialog.setForm(this.planForm);
        }
    }

    // TODO: Find an alternative, instead of relying on lifecycle hooks. https://zube.io/monsido/monsido/c/21921
    ngAfterContentChecked (): void {
        this.cdref.detectChanges();
    }

    submit (): void {
        if (!this.planForm.touched) {
            this.planForm.control.markAsPristine();
            this.close();
            return;
        }
        this.saving = true;
        const plan = DiffService.difference(this.localPlan, this.orgPlan);

        plan.price = this.localPlan.price;

        if (this.orgPlan.id) {
            plan.id = this.orgPlan.id;
        } else {
            plan.active = plan.active || false;
        }

        if (plan.unique_traits) {
            if (this.isEmpty(plan.unique_traits.heatmaps_traffic_percentage)) {
                delete plan.unique_traits.heatmaps_traffic_percentage;
            }

            if (this.isEmpty(plan.unique_traits.heatmaps_tracked_number_of_pages)) {
                delete plan.unique_traits.heatmaps_tracked_number_of_pages;
            }

            if (this.isEmpty(plan.unique_traits.performance_tracking_max_pages)) {
                delete plan.unique_traits.performance_tracking_max_pages;
            }

            if (this.isEmpty(plan.unique_traits.performance_tracking_max_profiles)) {
                delete plan.unique_traits.performance_tracking_max_profiles;
            }

            if (
                !Array.isArray(plan.unique_traits.performance_tracking_allowed_frequencies) ||
                plan.unique_traits.performance_tracking_allowed_frequencies.length === 0
            ) {
                delete plan.unique_traits.performance_tracking_allowed_frequencies;
            }

            if (plan.unique_traits.uptime_intervals) {
                plan.unique_traits.uptime_intervals = this.localPlan.unique_traits.uptime_intervals;
            }

            if (!Array.isArray(plan.unique_traits.uptime_intervals) || plan.unique_traits.uptime_intervals.length === 0) {
                delete plan.unique_traits.uptime_intervals;
            }

            if (plan.unique_traits.spell_check_engine_priority) {
                plan.unique_traits.spell_check_engine_priority = this.localPlan.unique_traits.spell_check_engine_priority;
            }

            if (
                !Array.isArray(plan.unique_traits.spell_check_engine_priority) ||
                plan.unique_traits.spell_check_engine_priority.length === 0
            ) {
                delete plan.unique_traits.spell_check_engine_priority;
            }
        }

        let promise: Promise<void>;
        if (plan.id) {
            promise = this.backendPlansRepo.update(plan as unknown as PlanInterface).then(
                () => {
                    this.resetFormState(this.planForm);
                    this.toastService.success(this.translateService.getString('Plan updated'));
                    this.close(true);
                },
                () => {},
            );
        } else {
            promise = this.backendPlansRepo.create(plan as unknown as PlanInterface).then(
                () => {
                    this.resetFormState(this.planForm);
                    this.toastService.success(this.translateService.getString('Plan created'));
                    this.close(true);
                },
                () => {},
            );
        }
        promise.finally(() => {
            this.saving = false;
        });
    }

    isEmpty (type: number | string): boolean {
        return !type || type === '' || type === undefined || type === null;
    }

    canSave (): string {
        return this.localPlan.name && this.localPlan.type && this.localPlan.group && this.localPlan.code;
    }

    close (forceUpdate?: boolean): void {
        this.activeDialog.close(this.localPlan, forceUpdate);
    }

    onAccessibilityModelChanged (model: boolean): void {
        if (!model) {
            this.plan.unique_traits.mobile_guidelines = false;
        }
    }

    onSelectionChange (): void {
        if (this.planForm) {
            // marking the form as touched because it does not perceive when a selection is changed;
            this.planForm.control.markAsTouched();
        }
    }
}
