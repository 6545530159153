import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Domain } from '@monsido/modules/models/api/domain';
import { MonPromptService } from '@monsido/angular-shared-components';
import {
    FormLoginType,
    Office365LoginType,
    CustomMultistepLoginType,
    BasicAuthLoginType,
    LinkExcludes,
    PathConstraints,
} from 'types/domain';
import { CsvPropType } from '@monsido/modules/csv/components/import-form/import-form.component';
import { DefaultConstraintsAndExcludesService } from '@monsido/services/default-constraints-and-excludes-service/default-constraints-and-excludes.service';
import { Subscription, skip } from 'rxjs';
import { Customer } from '@monsido/modules/models/api/customer';
import moment from 'moment/moment';
import { DefaultLinkExcludedInterface, DefaultPathConstraintInterface } from '@monsido/services/default-constraints-and-excludes-service/default-constraints-and-excludes.interface';
import {
    WEEK_DAY_OPTIONS,
    getTranslatedSelectOptions,
    SelectOption,
    CONNECTION_OPTIONS, LOGIN_TYPES_OPTIONS,
} from '@monsido/core/constants/select-options.constant';
import { PLAN_TRAITS } from '@monsido/core/constants/plan-traits.constant';
import { TranslateService } from 'app/services/translate/translate.service';

function typeCastTo<T> (): (obj: unknown) => T {
    return (obj): T => obj as T;
}

@Component({
    selector: 'mon-form-domain-scan',
    templateUrl: 'scan.html',
    styleUrls: ['./scan.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainScanComponent implements OnInit, OnDestroy {
    @Input() monDomain: Domain;
    @Input() monCustomer: Customer;
    @Input() monForm: NgForm;
    @Input() monIsBackendAdmin: boolean;

    @ViewChild('domainScanFieldsForm') public form: NgForm;

    dayOptions: SelectOption[] = [];
    connectionOptions: SelectOption[] = [];
    loginTypes: SelectOption[] = [];
    scanIntervalOptions: SelectOption[] = [];
    initialScanIntervalValue: number = -1;
    scanIntervalName: string = '';
    scanIntervalValue: number = -1;
    newScanInterval: number | null = null;

    private _scanTime?: string;

    set scanTime (value: string) {
        this._scanTime = value;
        this.monDomain.scan.time = moment(this._scanTime, 'HH:mm:ss').minutes(0)
            .format('HH:mm:ss');
    }
    get scanTime (): string {
        return this._scanTime;
    }

    $loginTypeForm = typeCastTo<FormLoginType>();
    $office365Type = typeCastTo<Office365LoginType>();
    $multistepType = typeCastTo<CustomMultistepLoginType>();
    $basicAuthType = typeCastTo<BasicAuthLoginType>();

    isContstraintsLoading = false;
    isLinkExcludesLoading = false;
    constraintsRightItemLabels: unknown[] = [];
    linkExcludesRightItemLabels: unknown[] = [];

    defaultText: string;
    labelDeleteConfirmation: string;
    labelTooltipText: string;

    private subscriptions: Subscription[] = [];
    private defaultTagValue = 'default';

    constructor (
        private translateService: TranslateService,
        private defaultConstraintsAndExcludesService: DefaultConstraintsAndExcludesService,
        private monPromptService: MonPromptService,
    ) {
        this.defaultText = this.translateService.getString('default');
        this.labelDeleteConfirmation = this.translateService.getString('Are you sure you want to remove the Default label?');
        // eslint-disable-next-line @stylistic/max-len
        this.labelTooltipText = this.translateService.getString(`Most users of your selected CMS apply this pattern when configuring their domain in Acquia Optimize.
        Edit the pattern or remove the Default label to permanently save it for your domain.`);

        this.dayOptions = getTranslatedSelectOptions(WEEK_DAY_OPTIONS, label => this.translateService.getString(label));
        this.connectionOptions = getTranslatedSelectOptions(CONNECTION_OPTIONS, label => this.translateService.getString(label));
        this.loginTypes = getTranslatedSelectOptions(LOGIN_TYPES_OPTIONS, label => this.translateService.getString(label));
    }

    ngOnInit (): void {
        if (this.monDomain.scan.login === null || typeof this.monDomain.scan.login !== 'object') {
            this.monDomain.scan.login = {
                type: 'none',
            };
        }
        this.scanTime = this.monDomain.scan.time;

        this.scanIntervalOptions = getTranslatedSelectOptions(PLAN_TRAITS.SCAN_FREQUENIES, label => this.translateService.getString(label));

        this.subscriptions.push(this.defaultConstraintsAndExcludesService.loadingPathConstraitsProgress$.subscribe((value) => {
            this.isContstraintsLoading = value;
        }));
        this.subscriptions.push(this.defaultConstraintsAndExcludesService.loadingLinksExcludedProgress$.subscribe((value) => {
            this.isLinkExcludesLoading = value;
        }));

        // We have to init without default data
        this.subscriptions.push(this.defaultConstraintsAndExcludesService.defaultPathConstraints$.pipe(skip(1)).subscribe((data) => {
            // We need to wait until this.monDomain.cms is updated
            setTimeout(() => {
                this.updatePathConstraints(data);
            });
        }));

        this.subscriptions.push(this.defaultConstraintsAndExcludesService.defaultLinkExcludes$.pipe(skip(1)).subscribe((data) => {
            // We need to wait until this.monDomain.cms is updated
            setTimeout(() => {
                this.updateLinkExcludes(data);
            });
        }));

        this.updatePathConstraints([]);
        this.updateLinkExcludes([]);

        this.prepareScanInterval();
    }

    ngOnDestroy (): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    onClose (input: { [name: string]: Record<string, string>[] | CsvPropType }): void {
        const { data, prop } = input;
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                switch (prop) {
                    case 'path_constraints':
                        this.monDomain[prop].push({ constraint: data[i][prop] });
                        break;
                    case 'link_excludes':
                        this.monDomain[prop].push({ regex: data[i][prop] });
                        break;
                }
            }
        }
    }

    onDeleteConstraintsDefaultLabelClick (index: number): void {
        const entry = this.monDomain.path_constraints[index];
        this.monPromptService
            .confirm(this.labelDeleteConfirmation)
            .then(() => {
                if (this.monDomain.path_constraints.includes(entry) && Array.isArray(entry.tags)) {
                    entry.tags.splice(0, 2);
                    this.constraintsRightItemLabels = this.makeRightDefaultLabels(this.monDomain.path_constraints);
                }
            }, () => {});
    }

    onDeleteLinksDefaultLabelClick (index: number): void {
        const entry = this.monDomain.link_excludes[index];
        this.monPromptService
            .confirm(this.labelDeleteConfirmation)
            .then(() => {
                if (this.monDomain.link_excludes.includes(entry) && Array.isArray(entry.tags)) {
                    entry.tags.splice(0, 2);
                    this.linkExcludesRightItemLabels = this.makeRightDefaultLabels(this.monDomain.link_excludes);
                }
            }, () => {});
    }

    onInputValueChanged (changes: { index: number }, type: string): void {
        const list = type === 'pathConstraints' ? this.monDomain.path_constraints : this.monDomain.link_excludes;
        const entry = list[changes.index];
        if (Array.isArray(entry.tags) && entry.tags[0] === this.defaultTagValue) {
            entry.tags.splice(0, 2);
            if (type === 'pathConstraints') {
                this.constraintsRightItemLabels = this.makeRightDefaultLabels(this.monDomain.path_constraints);
            } else {
                this.linkExcludesRightItemLabels = this.makeRightDefaultLabels(this.monDomain.link_excludes);
            }
        }
    }

    setScanInterval (): void {
        if (this.initialScanIntervalValue !== this.scanIntervalValue) {
            this.monDomain.scan.scan_interval = this.scanIntervalValue;
            this.initialScanIntervalValue = this.scanIntervalValue;
        }
    }

    private removeUnrelatedEntries<T extends { tags?: unknown[] }> (data:T[]): T[] {
        const cms = this.monDomain.cms;
        return data.filter(entry => {
            if (!entry) {
                return false;
            }
            if (!Array.isArray(entry.tags)) {
                return true;
            }
            if (entry.tags[0] === this.defaultTagValue && entry.tags[1] !== cms) {
                return false;
            }
            return true;
        });
    }

    private removeNewerDuplicates<T> (data: T[], keyName: string): T[] {
        const safeData = data.filter(entry => !!entry);

        return safeData
            .map((entry) => {
                return entry[keyName as keyof T];
            })
            .reduce((acc, current, index, entries) => {
                if (index === entries.lastIndexOf(current)) {
                    acc.push(safeData[index]);
                }
                return acc;
            }, []);
    }

    private makeRightDefaultLabels (items: LinkExcludes[] | PathConstraints[]): unknown[] {
        return items.map(entry => {
            if (entry.tags && entry.tags[0] === this.defaultTagValue) {
                return { name: this.defaultText };
            }
            return undefined;
        });
    }

    private prepareScanInterval (): void {
        let interval = this.monCustomer.plan_traits.scan_interval;

        if (this.monDomain.scan.scan_interval) {
            interval = this.monDomain.scan.scan_interval;
        }

        const scanInterval = this.scanIntervalOptions.find((option) => (option.value === interval)) || this.scanIntervalOptions[1];

        this.initialScanIntervalValue = this.scanIntervalValue = scanInterval.value as number;
        this.scanIntervalName = scanInterval.name;
    }

    private updatePathConstraints (data: DefaultPathConstraintInterface[]): void {
        if (this.monDomain && this.monDomain.path_constraints) {
            const planTraits = this.removeUnrelatedEntries(this.monDomain.path_constraints);
            this.monDomain.path_constraints = this.removeNewerDuplicates<PathConstraints>(data.concat(planTraits), 'constraint');
            this.constraintsRightItemLabels = this.makeRightDefaultLabels(this.monDomain.path_constraints);
        }
    }

    private updateLinkExcludes (data: DefaultLinkExcludedInterface[]): void {
        if (this.monDomain && this.monDomain.link_excludes) {
            const linkExcludes = this.removeUnrelatedEntries(this.monDomain.link_excludes);
            this.monDomain.link_excludes = this.removeNewerDuplicates<LinkExcludes>(data.concat(linkExcludes), 'regex');
            this.linkExcludesRightItemLabels = this.makeRightDefaultLabels(this.monDomain.link_excludes);
        }
    }
}
