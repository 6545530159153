import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { WorkerQueueComponent } from '@monsido/pages/backend-admin/worker-queue/worker-queue.component';
import { WORKERQUEUE_STATES } from '@monsido/pages/backend-admin/worker-queue/worker-queue.states';
import { BaseLayoutModule } from '@layout/layout.module';
import { LayoutModule, IconsModule } from '@monsido/angular-shared-components';

@NgModule({
    providers: [],
    imports: [BaseLayoutModule, UIRouterModule.forChild({ states: WORKERQUEUE_STATES }), LayoutModule, IconsModule],
    declarations: [WorkerQueueComponent],
})
export class WorkerQueueModule {}
