import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { tokenErrorMessages } from './token-issue-messages.constant';
import { OauthService } from '@monsido/oauth/oauth.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    private tokenRefreshed$: Subject<null> = new Subject();
    private isTokenInvalid = false;

    constructor (
        private oauthService: OauthService,
        private monEventService: MonEventService,
    ) {}

    intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.isTokenInvalid && !this.isOauthRequest(request.url)) {
            return this.replayRequestOnTokenRefreshed(request, next);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (this.issueWithAccessToken401(error)) {
                    if (this.isTokenInvalid) {
                        return this.replayRequestOnTokenRefreshed(request, next);
                    }

                    this.isTokenInvalid = true;
                    return this.oauthService.refreshAccessToken().pipe(
                        switchMap(() => {
                            this.setTokenRefreshed();
                            return next.handle(
                                this.oauthService.updateRequestToken(request),
                            );
                        }),
                        catchError(() => {
                            this.setTokenRefreshed();
                            // Force logout if refreshing token is failed
                            this.monEventService.run('logout');
                            return EMPTY;
                        }),
                    );
                }

                if (this.tokenRefreshingError(request)) {
                    this.monEventService.run('force logout');
                }
                return throwError(error);
            }),
        );
    }

    private replayRequestOnTokenRefreshed (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.tokenRefreshed$.pipe(
            switchMap(() => {
                return next.handle(
                    this.oauthService.updateRequestToken(request),
                );
            }),
        );
    }

    private isOauthRequest (url: string): boolean {
        return url.endsWith('/oauth/token');
    }

    private setTokenRefreshed (): void {
        this.isTokenInvalid = false;
        this.tokenRefreshed$.next(null);
    }

    private issueWithAccessToken401 (error: HttpErrorResponse): boolean {
        return Boolean(error && error.status === 401 && error.error && tokenErrorMessages.includes(error.error.message));
    }

    private tokenRefreshingError (request: HttpRequest<unknown>): boolean {
        return request.params.get('grant_type') === 'refresh_token';
    }

}
