import { UrlService } from '@uirouter/core';
import { DEFAULTS } from '@monsido/core/constants/defaults.constant';
import { isNaN } from 'lodash';

type ResolvableParamNamesType = 'currentSort' | 'activeTab' | 'search' | 'page' | 'pageSize';
interface UrlParamInterface {
    name: string;
    type: 'number' | 'string';
    default: number | string;
    propertyName: ResolvableParamNamesType;
}
export abstract class BaseApiComponent {
    urlParams: Array<UrlParamInterface> = [
        {
            name: 'page',
            type: 'number',
            default: 1,
            propertyName: 'page',
        },
        {
            name: 'page_size',
            type: 'number',
            default: DEFAULTS.API.PAGE_SIZE,
            propertyName: 'pageSize',
        },
        {
            name: 'search',
            type: 'string',
            default: '',
            propertyName: 'search',
        },
    ];

    loading: boolean = false;
    currentSort: string;
    activeTab: string;
    search: string;
    page: number = 1;
    pageSize: number = DEFAULTS.API.PAGE_SIZE;
    abstract getPage (): void;
    constructor (protected urlService: UrlService) {}

    loadLocations (shouldGetPage: boolean = true): void {
        if (this.urlParams) {
            for (const param of this.urlParams) {
                let value: string | number = decodeURIComponent((<Record<string, string>> this.urlService.search())[param.name]);

                switch (param.type) {
                    case 'number': {
                        const numberValue = parseInt(value);
                        value = isNaN(numberValue) ? param.default : numberValue;
                        break;
                    }
                    case 'string': {
                        const useDefaultValue = value == null || value === '';
                        value = useDefaultValue ? param.default : value;
                        break;
                    }
                }

                // this is a hack
                // BaseApiComponent expects the 'search' url param
                if (value === 'undefined') {
                    value = '';
                }

                (this[param.propertyName] as unknown) = value;
            }
        }

        if (shouldGetPage) {
            this.getPage();
        }
    }

    onStatus (tab: string): void {
        this.page = 1;
        this.activeTab = tab;
        this.getPage();
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
        this.page = 1;
        this.getPage();
    }

    onSearch (search: string): void {
        this.search = search;
        this.page = 1;
        this.getPage();
    }

    onPageChange (page: number): void {
        this.page = page;
        this.getPage();
    }

    onPageSizeChange (size: number): void {
        this.pageSize = size;
        this.page = 1;
        this.getPage();
    }

    onPageDetailsclose (): void {
        this.getPage();
    }

    onSortPage (sort: string): void {
        this.page = 1;
        this.currentSort = sort || this.currentSort; // The directive has already overwritten the variable
        this.getPage();
    }

}
