<button class="btn btn-marine-blue ml-1"
        (click)="sudo(customer.id, user)"
        *ngIf="customer">
    <mon-icon [styles]="{'margin-right': '5px'}" [icon]="['USERS', 'SUDO']"></mon-icon>
    <span translate>Sudo as user</span>
</button>

<button class="btn btn-marine-blue ml-1"
        (click)="sudo(customerAgreements()[0].account.id, user)"
        *ngIf="!customer && customerAgreements().length === 1">
    <mon-icon [styles]="{'margin-right': '5px'}" [icon]="['USERS', 'SUDO']"></mon-icon>
    <span translate>Sudo as user</span>
</button>

<mon-table-dropdown title="{{ 'Sudo as user' | translate }}"
                *ngIf="customerAgreements().length > 1">

    <ng-container *ngFor="let agreement of user.user_agreements">
        <li ngbDropdownItem role="menuitem" *ngIf="agreement.account.type === 'Customer'" >
            <a (click)="sudo(agreement.account.id, user)"
               href="#" 
               role="button"
               class="cursor-pointer">
                <mon-icon [styles]="{'margin-right': '5px'}" [icon]="['USERS', 'SUDO']"></mon-icon>
                <span>{{ agreement.account.name }}</span>
            </a>
        </li>
    </ng-container>

</mon-table-dropdown>
