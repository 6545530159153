import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { EULA } from '@monsido/core/contants/eula.constant';

@Component({
    selector: 'mon-customer-info-tab',
    templateUrl: './customer-info-tab.html',
    styleUrls: ['./customer-info-tab.scss'],
})
export class CustomerInfoTabComponent implements OnInit, OnChanges {
    @Input() customer: CustomerInterface;
    @Input() showCustomerId: boolean;
    eulaName: string;

    ngOnInit (): void {
        this.updateEulaName();
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.customer && changes.customer.currentValue?.eula?.category !== changes.customer.previousValue?.eula?.category) {
            this.updateEulaName();
        }
    }

    updateEulaName (): void {
        const customerEulaCategory = this.customer.eula?.category;
        if (customerEulaCategory) {
            this.eulaName = EULA.find((entry) => entry.category === customerEulaCategory)?.name || '';
        }
    }
}
