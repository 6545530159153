import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Account } from '@monsido/modules/models/api/account';

@Injectable()
export class AccountRepo {

    constructor (private client: ApiClient) {
    }

    get (): Promise<Account> {
        return this.client.getPromise('account');
    }
}
