import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { OauthService } from '@monsido/oauth/oauth.service';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { StateService, TransitionService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { ErrorHandlerService } from 'app/blocks/helpers/errorHandler/error-handler.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginRouteService {

    constructor (
        private monEventService: MonEventService,
        private $location: UrlService,
        private oauthService: OauthService,
        private state: StateService,
        private sessionService: SessionService,
        private transitionService: TransitionService,
        private uiRouterGlobals: UIRouterGlobals,
        private errorHandlerService: ErrorHandlerService,
        private globalHelperService: GlobalHelperService,
    ) {
        this.transitionService.onExit({ from: 'login.auth' }, () => {
            this.monEventService.run('afterLogin').then(() => {
                return this.monEventService.run('finishLogin');
            });
        });

    }

    async init (): Promise<void> {
        await this.monEventService.run('beforeLogin').then(() => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.$location.search().hasOwnProperty('code')) {
                return lastValueFrom(
                    this.oauthService
                        .getAccessToken(
                            this.$location.search().code,
                        ))
                    .then((oauthData) => {
                        return this.sessionService.login(oauthData);
                    }, this.errorHandlerService.noopError)
                    .then(async () => {
                        const state = this.oauthService.getState();

                        if (state) {
                            const params = this.oauthService.getStateParams();
                            this.oauthService.clearParams();
                            if (this.uiRouterGlobals.transition && (!this.uiRouterGlobals.transition.ignored() || this.uiRouterGlobals.transition.isActive())) {
                                this.uiRouterGlobals.transition.onSuccess({}, async () => {
                                    await this.state.go(state, params);
                                });
                            } else {
                                await this.state.go(state, params);
                            }
                        } else {
                            if (this.uiRouterGlobals.transition && (!this.uiRouterGlobals.transition.ignored() || this.uiRouterGlobals.transition.isActive())) {
                                this.uiRouterGlobals.transition.onFinish({}, async () => {
                                    await this.state.go('base.agreement_selector.index');
                                });
                                this.uiRouterGlobals.transition.onError({}, async () => {
                                    await this.state.go('base.agreement_selector.index');
                                });
                                this.uiRouterGlobals.transition.onSuccess({}, async () => {
                                    await this.state.go('base.agreement_selector.index');
                                });
                            } else {
                                await this.state.go('base.agreement_selector.index');
                            }
                        }


                    }, () => {});
            } else {
                this.globalHelperService.getWindowObject().location.href = this.sessionService.getAuthorizeUrl();
            }
        });
    }

}
