import { Injectable } from '@angular/core';
import {
    BackendAdminApiClient,
    createDefaultHttpParams,
} from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { Domain } from '@monsido/modules/models/api/domain';
import { HttpHeaders } from '@angular/common/http';
import { Params } from '@monsido/http/params';
import { DomainDataType } from 'types/domain';
import { Observable, map } from 'rxjs';

@Injectable()
export class BackendDomainsRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    getDomains (params: { [param: string]: string | string[] }, headers?: HttpHeaders, urlPrefix?: string): Promise<Domain[]> {
        return this.adminClient.getPromise(`domains`, this.createGetDomainsOptions(params, headers), urlPrefix).then(this.toModels.bind(this));
    }

    getDomains$ (params: { [param: string]: string | string[] }, headers?: HttpHeaders, urlPrefix?: string): Observable<Domain[]> {
        return this.adminClient.getObservable<Domain[]>(`domains`, this.createGetDomainsOptions(params, headers), urlPrefix)
            .pipe(map(domains => this.toModels(domains)));
    }

    get (customerId: number, domainId: number, options?: Params, urlPrefix?: string): Promise<Domain> {
        return this.adminClient.getPromise(`customers/${customerId}/domains/${domainId}`, options, urlPrefix)
            .then(this.toModels.bind(this));
    }

    update (customerId: number, domainId: number, domain: Partial<DomainDataType>): Promise<Domain> {
        return this.adminClient.patchPromise(`customers/${customerId}/domains/${domainId}`, domain).then(this.toModels.bind(this));
    }

    create (customerId: number, domain: DomainDataType, headers: HttpHeaders = null): Promise<Domain> {
        const params = createDefaultHttpParams(null, headers);
        return this.adminClient.postPromise(`customers/${customerId}/domains`, domain, params).then(this.toModels.bind(this));
    }

    destroy (customerId: number, domainId: number, options?: Params): Promise<void> {
        return this.adminClient.deletePromise(`customers/${customerId}/domains/${domainId}`, options);
    }

    rescan (customerId: number, domainId: number, params?: Record<string, string | number>): Promise<void> {
        params = params || {};
        return this.adminClient.postPromise(`customers/${customerId}/domains/${domainId}/crawls`, params);
    }

    rewriteUrl (params: Record<string, string | number>): Promise<void> {
        params = params || {};
        return this.adminClient.postPromise(`customers/${params.customerId}/domains/${params.domainId}/change-url`, params);
    }

    private toModels (models: Domain[]): Domain[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: Domain): Domain {
        return new Domain(model);
    }

    private createGetDomainsOptions (params: { [param: string]: string | string[] }, headers?: HttpHeaders): Params {
        const options: Params = {};
        params = params || {};
        options.params = params;
        if (headers) {
            options.headers = headers;
        }

        return options;
    }
}
