import { Injectable } from '@angular/core';
import { AccessibilityCheckModel, ResposibilityType, TagType } from '../accessibility-check-model';
import { TranslateService } from 'app/services/translate/translate.service';


export type OutputAccessibilityCheckType = AccessibilityCheckModel & {
    impactName: string;
    priorityName: string;
    tagsNames: TagType[];
    responsibilitiesNames: ResposibilityType[];
};

@Injectable({
    providedIn: 'root',
})
export class OutputAccessibilityCheckMakerService {

    constructor (
        private translateService: TranslateService,
    ) {}

    decorate (check: AccessibilityCheckModel): OutputAccessibilityCheckType {
        return Object.assign(check, {
            impactName: this.getImpactName(check),
            priorityName: this.getPriorityName(check),
            tagsNames: this.getTagsName(check),
            responsibilitiesNames: this.getResponsibilitiesName(check),
        });
    }

    private getImpactName (check: AccessibilityCheckModel): string {
        const impact = check.impactList.find((i) => i.value === check.impact);
        if (!impact) {
            return this.translateService.getString('No Impact level set');
        }

        return impact.name;
    }

    private getPriorityName (check: AccessibilityCheckModel): string {
        const priority = check.levelList.find(p => p.value === check.difficulty);
        if (!priority) {
            return this.translateService.getString('No difficulty set');
        }

        return priority.name;
    }

    private getTagsName (check: AccessibilityCheckModel): TagType[] {
        if (Array.isArray(check.tags)) {
            return check.tagList.filter(t => {
                return check.tags.indexOf(t.value) > -1;
            });
        }
        return [];
    }

    private getResponsibilitiesName (check: AccessibilityCheckModel): ResposibilityType[] {
        if (Array.isArray(check.responsibilities)) {
            return check.responsibilityList.filter(r => {
                return check.responsibilities.indexOf(r.value) > -1;
            });
        }
        return [];
    }
}
