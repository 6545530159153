<table class="table table-hover table-fixed">
    <thead>
    <tr>
        <th class="col-lg-2">
            <mon-table-select-item-all [(selectedItems)]="selectedUsers" [items]="users">
            </mon-table-select-item-all>
        </th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>User</th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Customer</th>
        <th class="col-sm-12 col-md-12 col-lg-10" translate>Partner</th>
        <th class="col-sm-8 col-md-7 col-lg-4" translate>Latest login</th>
        <th class="col-sm-8 col-md-7 col-lg-4" translate>Created at</th>
        <th class="text-right col-sm-5 col-md-5 col-lg-4" translate></th>
        <th class="text-right col-sm-5 col-md-5 col-lg-4" translate *ngIf="['admin', 'reseller_admin', 'reseller'] | monRole"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
        <td>
            <mon-table-select-item class="inline-block" [(selectedItems)]="selectedUsers" [item]="user"></mon-table-select-item>
        </td>
        <td>
            <mon-email-avatar [user]="user"></mon-email-avatar>
        </td>
        <td>
            <ng-container *ngFor="let agreement of user.user_agreements">
                <ul class="list-unstyled d-flex flex-column text-ellipsis"
                    *ngIf="agreement.account && agreement.account.type === 'Customer'">
                    <li class="text-ellipsis">
                        <a uiSref="base.backend_admin.customers.show" [uiParams]="{customer_id: agreement.account.id}">
                            {{agreement.account.name}}
                        </a>
                    </li>
                </ul>
            </ng-container>
        </td>
        <td>
            {{ resellerAgreement(user)?.name }}
        </td>
        <td>
            <mon-user-date [twoLines]="true" [date]="user.last_login_at"></mon-user-date>
        </td>
        <td>
            <mon-user-date [twoLines]="true" [date]="user.created_at"></mon-user-date>
        </td>
        <td class="text-right">
            <mon-table-dropdown *ngIf="user.customer_id !== null">
                <li role="menuitem">
                    <a (click)="onEditUser(user)">
                        <span ngClass="['ACTIONS', 'EDIT'] | monIcons"></span>
                        <span translate>Edit</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a monConfirm="{{'Are you sure you want to delete this user?' | translate}}"
                    (monConfirmAction)="onDeleteUser(user)"
                    class="cursor-pointer">
                        <span ngClass="['ACTIONS', 'DELETE'] | monIcons"></span>
                        <span translate>Delete</span>
                    </a>
                </li>
            </mon-table-dropdown>
        </td>
        <td class="text-right">
            <mon-sudo-button [user]="user" (sudoEvent)="onSudo($event)"></mon-sudo-button>
        </td>
    </tr>
    </tbody>
</table>
