import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderValidationModule, FormsBuilderModule } from '@monsido/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { AccessibilityCheckType } from 'app/services/api/support/models/accessibility-check-model';
import { TranslateModule } from 'app/modules/translate.module';

type RuleType = { type: string, value: string, accessibility_check_ids: number[] };

@Component({
    selector: 'mon-source-code-exclude-entry',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        FormsBuilderModule,
        FormBuilderValidationModule,
        FormsModule,
        MonIconsPipe,
    ],
    templateUrl: './source-code-exclude-entry.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceCodeExcludeEntryComponent {

    @Input() typeOptions: { name: string, value: string }[];
    @Input() rule: RuleType;
    @Input() canBeDeleted: boolean = true;
    @Input() accessibilityChecks: AccessibilityCheckType[] = [];

    @Output() remove: EventEmitter<void> = new EventEmitter<void>();
    @Output() update: EventEmitter<void> = new EventEmitter<void>();

    ruleId = Math.random().toString(16)
        .slice(2);

    removeEntry (): void {
        this.remove.emit();
    }

    updateEntry (): void {
        // Ensure emitting after ngModel dispatches its update
        setTimeout(() => {
            this.update.emit();
        });
    }
}
