<div class="backend-admin-crawl-queue">
    <mon-page-header [monIcon]="['CRAWL_QUEUE', 'ICON']"
                    header="{{'Crawl queue' | translate}}"
                    subHeader="{{'Overview of all crawls that are in progress and in queue' | translate}}">
    </mon-page-header>

    <mon-tabs slot="mon-panel-tabs"
              [options]="tabs"
              [status]="activeTab"
              [maxOptionsDisplay]="7"
              (onStatus)="tabChange($event)">
            <div class="d-flex justify-content-end">
                <mon-table-search [minSearchLength]="3"
                                  placeholderTxt="{{'Search...' | translate}}"
                                  [model]="search"
                                  (getPage)="onSearch($event)"
                                  data-test="search-input">
                </mon-table-search>
            </div>
    </mon-tabs>
    <mon-panel [showHeader]="false">
            <div class="card monsido-panel crawls-time-card" slot="mon-panel-body">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <span translate>Total count </span><span> {{ crawls.length }} scans</span><br>
                            <span translate>On </span><span> {{ customerList.length }} </span><span translate>customers</span>
                        </div>
                        <div class="col">
                            <span class="pull-right" *ngIf="timeSince">{{ 30 - timeSince }} second(s) to next update</span>
                        </div>
                    </div>
                </div>
            </div>
        <mon-table-container [collection]="crawls" class="table-vertial-align" [loading]="loading" slot="mon-panel-body">
            <div class="table-container-body crawls-table">
                <table class="table table-hover table-fixed">
                    <thead>
                        <tr>
                            <th class="col-sm-2">#</th>
                            <th class="col-sm-10" translate>Domain</th>
                            <th class="col-sm-5" translate>Customer</th>
                            <th class="col-sm-3" translate>Partner</th>
                            <th class="col-sm-5 text-center" translate>State</th>
                            <th monTableSort
                                [monThTitle]="'Started' | translate"
                                monSortBy="queued_at"
                                [monSortDirection]="direction"
                                [monCurrentlySortedBy]="sortedBy"
                                (monOnSorted)="onSort($event)"
                                class="col-sm-3 fw-bold">
                            </th>
                            <th class="col-sm-2" translate>Runtime</th>
                            <th class="col-sm-4 text-right" translate>Scanned pages</th>
                            <th class="col-sm-4 text-right" translate>Estimated pages</th>
                            <th class="col-sm-4 text-right" translate>Scanned links</th>
                            <th class="col-sm-4 text-right" translate>Remaining links</th>
                            <th class="col-sm-3 text-right" translate>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let crawl of crawls">
                            <tr>
                                <td class="text-v-middle">
                                    {{ crawl.domain.id }}
                                </td>
                                <td class="text-ellipsis text-v-middle">
                                    <a uiSref="base.backend_admin.customers.info.domain"
                                       [uiParams]="{customer_id: crawl.customer.id, domain_id: crawl.domain.id}">
                                        {{ crawl.domain.title }}
                                    </a><br />
                                    <a [href]="crawl.domain.url" class="text-ada-grey" target="_blank">{{crawl.domain.url}}</a>
                                </td>
                                <td class="text-ellipsis text-v-middle">
                                    <a uiSref="base.backend_admin.customers.show" [uiParams]="{customer_id: crawl.customer.id}">
                                        {{ crawl.customer.name }}<br>
                                        <span class="text-ada-grey" translate>Customer id:</span><span class="text-ada-grey"> {{ crawl.customer.id  }}</span>
                                    </a>
                                </td>
                                <td class="text-ellipsis text-v-middle">
                                    <span [ngbTooltip]="crawl.reseller_name" placement="top-left">{{ crawl.reseller_name }}</span>
                                </td>
                                <td class="text-ellipsis text-v-middle text-center">
                                    <span *ngIf="!(crawl.process_info && crawl.process_info.host)">{{crawl.state}}</span>
                                    <span *ngIf="crawl.process_info && crawl.process_info.host">{{crawl.state}}<br>({{ crawl.process_info.host }})</span>
                                
                                </td>
                                <td class="text-v-middle">
                                    <span [ngbTooltip]="crawl.queued_at | amDateFormat:'LL'"> {{crawl.queued_at | amTimeAgo}}</span>
                                </td>
                                <td class="text-v-middle">{{ crawl.queued_at | monTimeDiff}}</td>
                                <td class="text-right text-v-middle">{{crawl.page_count | number}}</td>
                                <td class="text-right text-v-middle">{{crawl.estimated_page_count | number}}</td>
                                <td class="text-right text-v-middle">{{crawl.scanned_count | number}}</td>
                                <td class="text-right text-v-middle">{{crawl.new_links | number}}</td>
                                <td class="text-right capitalize-first-letter text-v-middle">
                                    <button monConfirm="Confirm stop crawl?"
                                            (monConfirmAction)="stopCrawl(crawl)"
                                            class="btn btn-secondary"
                                            *ngIf="crawl.state === CRAWL.STATE.STARTED || crawl.state === CRAWL.STATE.QUEUED || crawl.state === CRAWL.STATE.BACKOFF || crawl.state === CRAWL.STATE.RETRY"
                                            translate>
                                        Stop
                                    </button>
                                    <button (click)="openCrawl($event, crawl)"
                                            class="btn btn-secondary"
                                            *ngIf="crawl.state === CRAWL.STATE.ON_HOLD || crawl.state === CRAWL.STATE.SUPPORT || crawl.state === CRAWL.STATE.ACCESSIBILITY_PROBLEM"
                                            translate>
                                        Review
                                    </button>
                                </td>
                            </tr>
                            <tr class="selected">
                                <td colspan="12" class="text-ellipsis" title="{{crawl.details}}">
                                    <mon-icon [styles]="{'margin-right': '10px'}" [icon]="['SPINNER', 'SYNC']" [spin]="true" *ngIf="crawl.crawling === true"></mon-icon>
                                    {{crawl.details}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </mon-panel>
</div>
